@import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');

  @font-face {
    font-family: 'Bellenia';
    src: url('./utils/fonts/bellania/Bellenia_Love.ttf') format('truetype');
  }

body{
    margin: 0px;
    font-family: 'Raleway', sans-serif;
    overflow-x: hidden;
}

.artist_length1{
    border-radius:50% !important;
    height:96px !important;
    width:96px !important;
    object-fit: cover;
}
.artist_length2{
    border-radius:50% !important;
    margin:5px  5px 0px 5px !important;
    height:96px !important;
    width:96px !important;
    object-fit: cover;
    object-position: 0% 50%;
}
.artist_length3{
    border-radius:50% !important;
    margin:5px  5px 0px 5px !important;
    height:88px !important;
    width:88px !important;
    object-fit: cover;
}
.artist_length4{
    border-radius:50% !important;
    margin:30px  5px 0px 5px !important;
    height:64px !important;
    width:64px !important;
    object-fit: cover;
}
.artist_length5{
    border-radius:50% !important;
    margin:5px  5px 0px 5px !important;
    height:54px !important;
    width:54px !important;
    object-fit: cover;
}
.artist_length6{
    border-radius:50% !important;
    margin:5px  5px 0px 5px !important;
    height:54px !important;
    width:54px !important;
    object-fit: cover;
}

.MuiGrid-root{
    margin-top:0px !important;
}
.MuiInputBase-input{
    height: 0.7445em !important;
    font-size: 16px !important;
}

.MuiDialogContentText-root{
    line-height: 1 !important;
}

#profile-email-input, #profile-text-input{
    height: 0.1445em !important;
}

.loginSubmitBtn{
    height: 0.7445em !important;
}

.nextBtnClass{
    height: 0.7445em !important;
    position:"absolute";
    top:0;
    right:0;
    font-style: "normal";
    font-weight: "600";
    font-size: "12px";
    line-height: "14px";
    color: "#913713";
}

.profileData{
    position:relative;
}

.profileData p{
    margin: 0;
    word-wrap: break-word;
    
}

.profileSection{
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 3px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    margin-top:20px;
    /* margin-bottom:20px; */
    padding: 16px 24px;
}

.profileSectionMobile{
    margin-top: 35px !important;
}

.not_found_img.activity_not_found{
    margin-bottom: 0;
    margin-top:20px;
}
.MuiSwitch-thumb{
    width: 11px !important;
    height: 11px !important;
    margin-top: 4px !important;
    margin-left: 6px !important;
}
.Mui-checked {
    color: #ffffff !important;
    
}
.Mui-checked>.MuiSwitch-thumb{
    margin-left: 3px !important;
    color: #fff !important;

}
.MuiSwitch-track{
    background-color: #D5D7DB !important;
    opacity: 1 !important;
}
.Mui-checked+.MuiSwitch-track{
    background-color: #913713 !important;
    opacity: 1 !important;
}

.toastBox{
    width: 70%;
    position: absolute;
    top: 0;
    padding-top: 15px;
}

.notification_content>span{
    font-size: 10px !important;
}
.notification_text>span{
    font-size: 12px !important;
}

.pageName{
    line-height: 28px !important;
}


@media (max-width: 600px) {
    .profile-close-icon{
        right: 16px !important;
    }
    .login-grid-1{
        display: none !important;
    }
    .mobile-login-logo{
        display: block !important;
    }
    .login-heading{
        padding-top: 50px !important;
    }
    .notifications-grid{
        padding:0px !important;
    }
    .profileSection{
        margin-top: 90px !important;
    }
    .profileSectionMobile{
        margin-top: 105px !important;
    }
    
    
    
}
@media (max-width: 280px) {
    .MuiInputBase-input {
        font-size: 12px !important;
    }
    .notification_box {
        right: -70px !important;
        
    }
    .notification_container {
        
        width: 250px !important;
    }
    
}
@media (max-width: 360px) {
    .pageName {
        font-size: 15px !important;
    }
}
@media (min-width: 361px) and (max-width:768px) {
    .pageName{
        font-size: 16px !important;
    }
}
@media (max-width: 480px){
    .profile-close-icon{
        right: 13px !important;
    }
    .changepwd-nextBtn{
        padding:20px 30px !important;
    }
    .changepwd-backBtn{
        padding:20px 30px !important;
    }
    .profile-details-logoBox{
        bottom: -7px !important;
        right: -39px !important;
        width: 146px !important;
    }
    .profile-details-logo{
        width: 55% !important;
    }
    .MuiInputBase-input {
        font-size: 10px !important;
    }
    
}
@media (min-width: 360px) and (max-width:480px) {
    .notification_box {
        right: -70px !important;
        
    }
    .notification_container {
        
        width: 300px !important;
    }
}
@media (min-width: 481px) and (max-width:600px) {
    .notification_box {
        right: -120px !important;
        
    }
}
@media (min-width: 601px) and (max-width:768px) {
    .profileSection{
        margin-top: 50px !important;
    }
    .profileSectionMobile{
        margin-top: 105px !important;
    }
    .profile-detail-mobileBox{
        height: 180px !important;
    }
}

@media (min-width: 600px){
    /* .login-grid-1{
        display: block;
    } */
    .changepwd-nextBtn{
        padding:20px 20px !important;
    }
    .changepwd-backBtn{
        padding:20px 20px !important;
    }
    
    
}
@media (max-width: 600px){
    .profile-detail-mobileBox{
        height: 315px !important;
    }
    .do-not-disturb{
        padding: 10px 0px !important;
    }
    
}
@media (max-width: 768px){
    .profile-grid-1{
        min-height: 150px !important;
    }
    
    .profileData{
        padding-left: 25px;
    }
    
    .allSettings{
        padding-left: 0px !important;
    }
    .notification_content>span{
        font-size: 12px !important;
    }
    .notification_text>span{
        font-size: 14px !important;
    }
    
}

@media (min-width: 768px){
    
    .changepwd-nextBtn{
        padding:20px 30px !important;
    }
    .changepwd-backBtn{
        padding:20px 30px !important;
    }
}

@media (min-width: 992px) {
    .changepwd-nextBtn{
        padding:20px 50px !important;
    }
    .changepwd-backBtn{
        padding:20px 50px !important;
    }
}

@media (min-width: 540px) and (max-width:719px) {
    .artist_length3{
        margin:5px  5px 0px 5px !important;
        height:130px !important;
    }
    .artist_length4{
        margin: 20px 0px 0px 5px !important;
        height: 100px !important;
    }
}

@media (min-width: 768px) and (max-width:819px) {
    .artist_length2{
        margin:15px  5px 0px 5px !important;
        height:90px !important;
    }

    .artist_length3{
        margin:25px  5px 0px 5px !important;
        height:60px !important;
    }

    .artist_length4{
        margin: 35px 0px 0px 5px !important;
        height: 50px !important;
    }
}

@media (min-width: 820px) and (max-width:992px) {
    .artist_length2{
        margin:10px  5px 0px 5px !important;
        height:110px !important;
    }

    .artist_length3{
        margin:20px  5px 0px 5px !important;
        height:75px !important;
    }

    .artist_length4{
        margin: 35px 0px 0px 5px !important;
        height: 55px !important;
    }
}

@media (min-width: 768px) and (max-width:992px) {
    
    .profile-details-logoBox{
        bottom: -7px !important;
        right: -39px !important;
        width: 146px !important;
    }
    .profile-details-logo{
        width: 55% !important;
    }
    .modal_form_popup .MuiDialog-paper,.modal_popup_simple .MuiDialog-paper{
        width: 53.5vw !important;
    }
    .header_right{
        margin-right: 40px !important;
    }
    .notification_content>span{
        font-size: 15px !important;
    }
    .notification_text>span{
        font-size: 17px !important;
    }
}
@media (min-width: 992px) and (max-width:1199px) {
    .modal_form_popup .MuiDialog-paper,.modal_popup_simple .MuiDialog-paper{
        width: 48.5vw !important;
    }
}

@media (min-width: 1200px) {
.changepwd-nextBtn{
    padding:20px 60px !important;
}
.changepwd-backBtn{
    padding:20px 60px !important;
}
.modal_form_popup .MuiDialog-paper,.modal_popup_simple .MuiDialog-paper{
    width: 38.5vw !important;
}
}

.edit-profile-loader{
    width: 20px !important;
    height: 9px !important;
    margin-left: 10px !important;
}

.button-loader{
    width: 20px !important;
    height: 35px !important;
    margin-left: 10px !important;
}

/* hide sidebar scollbar */
.MuiDrawer-paper::-webkit-scrollbar{
    display: none;
}

.sidebar-divider{
    display: flex;
    justify-content: center;
    border-color:#FCECD1 !important;
}
.sidebar-divider>.MuiDivider-root{
    border-color:#FCECD1 !important;
}

.MuiDrawer-paperAnchorLeft{
    border-right:1px solid #fff !important;
}

@media (max-width: 280px) {

    .artist_length2{
        margin:15px  5px 0px 5px !important;
        height:110px !important;
    }

    .artist_length3{
        margin:25px  5px 0px 5px !important;
        height:70px !important;
    }

    .artist_length4{
        margin: 30px 0px 0px 5px !important;
        height: 55px !important;
    }
    
    .pageName {
        font-size: 11px !important;
    }
    .content_container {
        margin-top: 20px !important;
    }
    .profile-details-logoBox {
        bottom: -4px !important;
        right: -35px !important;
        width: 90px !important;
    }
    .profileSection {
        padding: 16px 8px;
    }
    .recent-activity-avatar{
        padding-left: 20px !important;
    }
    .setting-on-off{
        text-align: center !important;
    }
    .header_right{
        margin-right: 4px !important;
    }
    .header-mobile-menu-btn{
        padding-right: 0px !important;
    }
    .notification_content>span{
        font-size: 10px !important;
    }
    .notification_text>span{
        font-size: 12px !important;
    }
    .do-not-disturb{
        padding: 9px 0px !important;
        min-width: 106px !important;
    }
}

.MuiTableRow-root:hover{
  background-color: #F9DFE0 !important;
}

.changePwdBtn:disabled,
.changePwdBtn[disabled]{
  
  background-color: #9FA1A6 !important;
  color:#ffffff !important;
  
}
.previewBanner{
  
  border:1px solid #9FA1A6 !important;
  background-color: #ffffff !important;
  color:#9FA1A6 !important;
  
}
.removeColumn>button>span{
    font-size: 12px !important;
}
.timeBtn{
    height:20px !important;
    background-color:#FFFFFF !important;
    color:#7E8085 !important;
    font-size:12px !important;
    padding:0px !important
}
.timeBtn>span{
    font-size: 12px !important;
}
.timetext{
    position: absoulte !important;
    top:0;
}
#start_time,#end_time{
    padding-left: 35px;
}
#start_time_hour,#start_time_min{
    padding-left: 30px;
}

#timeBox1,#timeBox2{
    position:absolute;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 6px 16px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    top: 77px;
    z-index: 1;
    background: #ffffff;
    padding: 20px 35px 44px 35px;
}

.custom-datepicker{
    height: 2.7445em !important;
    font-size: 16px !important;
    margin-top: 10px !important;
    padding-left: 35px !important;
    border-radius:4px !important;
    border: 1px solid #D5D7DB;
    color: #503B35 !important;
}

.event_date_grid_item .custom-datepicker{
    width: 96%;
}


.disable-select{
    pointer-events: none !important;
}

/* calendar */


.react-datepicker__header{
    background-color: #ffffff !important;
    border-bottom: 1px solid #ffffff !important;
}

.react-datepicker__current-month{
    color: #221714 !important;
}

.react-datepicker__day-name{
    color: #9fa1a6 !important;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
    border-bottom-color: #ffffff !important;
}

.react-datepicker__navigation-icon::before {
    border-color: #9fa1a6 !important;
}

.react-datepicker__day {
    color: #221714 !important;
}

.react-datepicker__day--selected{
    background-color: #913713 !important;
    color: #ffffff !important;
}

/* calendar */

.event-step2-toggle{
    font-family: 'Inter' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 18px !important;
    
}

.disableFormTab{
    pointer-events: none;
}



