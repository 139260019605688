.gallery_page_top_website{
    padding-top: 150px;
    padding-bottom: 60px;
}

.gallery_page_top_navigation_website .back_img{
    position: absolute;
    left: 0px;
    top: 0px;
}

.gallery_page_top_navigation_website{
    position: relative;
}

.gallery_page_top_navigation_website h4{
    font-style: normal;
font-weight: 400;
font-size: 60px;
line-height: 80px;
text-align: center;
font-family: 'Open Sans';
color: #FDFCFC;
margin-top: 0px;
margin-bottom: 0px;
}

.gallery_page_website{
    padding-bottom: 60px;
}
.image_container
{
    /* background-color: red; */
    object-fit: contain;
}
.image_gallery_container img {
    padding: 8px;
    border-radius: 18px;
}
.image_container_heading
{
    text-transform: capitalize;
    color: #FDFCFC;
    font-family: "Open Sans";
    font-size: 50px;

}