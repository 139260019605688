.announcement_box{
    border: 1px solid #FAE4DB;
    padding: 20px 15px;
    margin-bottom: 15px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
}

.menu_box{
    position: relative;
}

.toggle_pos{
    position: absolute;
    right: 90px;
    top: 20px
}

.toggle_pos li{
    cursor: pointer;
}