.carousel_slide_preview_banner{
    background: #140A0A;
    /* padding: 30px */
}
 @media screen and (max-width: 1550px){
 .carousel_slide_preview_banner .hero_heading {
    font-size: 23px !important;
 }
 .carousel_slide_preview_banner  .artist_image_line_new::before{
    top: 0px !important;
    left: 6px !important;
 }
}