.donate_top{
    /* padding-top: 150px; */
    padding-top: 50px;
    padding-bottom: 60px;
}

.donate_top_subtitle{
    font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 16px;
/* margin-top: 20px; */
/* or 114% */

text-align: center;
letter-spacing: 0.1px;


color: #BABDC2;
padding-left: 20%;
padding-right: 20%;
}

.donate_inputs{
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-bottom: 60px;
    color: '#FFF';
}

.donate_inputs input{
    background: transparent;
    border: unset;
    width: 200px;
    border-bottom: 1px solid #fff;
    margin-right: 15px;
    padding: 10px;
    color: '#FFF';
}

.donate_prices_box{
    display: flex;
    justify-content: center;
    padding-bottom: 150px;
    color: '#FFF';
}

.donate_price_box{
    cursor: pointer;
    width: 80px;
    height: 40px;
    background: rgba(255, 255, 255, 0.1);
border-radius: 4px;
margin-left: 15px;
margin-right: 15px;

font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 18px;
/* identical to box height, or 129% */


/* Ochre/50 */

color: #FDFCFC;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
}

.donate_mobile_button{
    display: none !important;
}
.save_icon
{
    background-color: grey;
    padding: 5px;
    border-radius: 50%;
    position: fixed;
    bottom: 10px;
    right: 10px;
    z-index: 1;
    opacity: 1;
}
.input_tag
{

    text-decoration: none;
    border: none;
    background-color:  transparent;
    /* outline: none; */
    /* color: white; */
    width: 100%;
    max-width: 100%;
    height: auto;
    transition: all 0.2s ease;
    resize: none;
    /* white-space: wrap; */
    /* text-wrap: wrap; */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 25px;
    overflow: hidden;
    color: white;
    /* or 114% */    
    /* text-align: center; */
    letter-spacing: 0.1px;

}
.donate_content
{
    text-decoration: none;
    border: none;
    background-color:  transparent;
    width: 100%;
    max-width: 100%;
    height: auto;
    transition: all 0.2s ease;
    resize: none;
    overflow: hidden;
    letter-spacing: 0.1px;
    text-align: center;
    letter-spacing: 0.1px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 19px;
    width: 70%;

}
.input_tag_wrapper
{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

@media screen and (max-width:821px) {
    .donate_top_subtitle{
        padding-left: 0px;
        padding-right: 0px;
    }
    .donate_inputs{
        flex-direction: column !important;
        padding-bottom: 10px !important;
    }
    .donate_inputs input{
        margin-bottom: 15px;
        width: calc(100% - 20px);
        margin-right: 0px;
        text-align: center;
    }
    .donate_mobile_button{
        display: block !important;
        padding-bottom: 150px;
    }
    .donate_mobile_button button{
        width: 100%;
    }
    .donate_prices_box{
        padding-bottom: 45px;
    }
    .donate_inputs button{
        display: none !important;
    }
}