.modal_popup_simple_banner .MuiDialog-paper{
    width: 60vw;
    padding: 25px 20px;
    border-radius: 8px;
    max-width: 60vw !important;
}

.modal_popup_simple_banner .modal_title{
    padding-bottom: 30px;
}

.modal_popup_simple_banner .MuiDialog-container{
    margin-left: 120px;
}

.modal_popup_simple_banner .MuiDialogContent-root{
    padding-bottom: 25px !important;
}

.MuiDialogActions-root{
    justify-content: space-between !important;
    padding-left: 24px !important;
    padding-right: 24px !important;
}

.MuiDialogActions-root button{
    width: 47.5%;
}

@media screen and (max-width: 821px) {
    .modal_popup_simple_banner .MuiDialog-paper{
        width: 100vw;
        padding: 12.5px 0px;
    }
    .modal_popup_simple_banner .MuiDialog-container{
        margin-left: 0px !important;
    }
}