.modal_popup_message .MuiDialog-paper{
    width: 60vw;
    padding: 25px 20px;
    border-radius: 8px;
    max-width: 60vw !important;
}

.modal_popup_message .rdw-editor-main{
    height: 160px !important;
    border: 1px solid #E8EAED;
border-radius: 4px;
padding-left: 10px;
font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 14px !important;
letter-spacing: 0.1px;
color: #BABDC2;
}

.modal_popup_message_buttons{
    width: 60%;
}