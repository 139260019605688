.outerConatinerBox{
    padding: 20px 30px;
    border: 2px solid #FFDAD9;
    border-radius: 8px;
}

.inputLabel{
    font-size: 14px;
    line-height: 18px;
    color: #221714;  
    font-weight: 600;
}

.errorPara{
    font-size: 12px;
    margin: 0;
    padding: 0;
    font-weight: 700;
    color: red;
}