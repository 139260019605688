.dropzone_container .dropzone {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23903713FF' stroke-width='2' stroke-dasharray='6%2c 6' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 8px;
  height: 220px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.remove_icon {
  height: 20px;
  width: 20px;
  position: absolute;
  right: -10px;
  top: -10px;
}

.replace {
  color: #913713 !important;
  padding: 12px !important;
}

.artistImageBox canvas {
  border: 1px solid black;
  object-fit: contain;
  height: 258px;
  margin-top: 8px;
  border-radius: 4px;
}
