.past_event_section {
  padding-top: 60px;
  padding-bottom: 60px;
}

.past_event_heading_img_box {
  margin-top: -26px !important;
  margin-left: 14px !important;
}

.past_event_heading_img {
  width: 195px;
}

.event_grid {
  display: flex;
  justify-content: center;
}

.event_grid_container {
  /* margin-top: 60px !important; */
}

.event_image {
  position: relative;
}

.event_image img {
  border-radius: 500px 500px 8px 8px;
}

.event_image::after {
  content: "";
  position: absolute;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #140a0a 89.92%);
  width: 100%;
  height: 100%;
  left: 0px;
}

.event_text {
  padding-left: 25px;
  margin-top: -130px;
  position: absolute;
  z-index: 99;
}

.event_content {
  position: relative;
}

.event_title {
  font-family: "Raleway";
  font-style: normal;
  font-size: 20px;
  font-weight: 400;
  color: #fff;
  margin-bottom: 5px;
  text-transform: capitalize;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.event_date {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #fff;
}

.event_link_container {
  width: 100%;
  text-align: right;
}

.event_link_btn a {
  text-decoration: none;
}

.event_link_btn p {
  color: #fff;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  display: flex;
  justify-content: center;
}

.event_link_btn img {
  margin-left: 10px;
}

.past_event_image_website {
  width: 100%;
  height: 260px !important;
  object-fit: cover;
}

.event_past_link {
  width: 70%;
}

@media screen and (max-width: 821px) {
  .event_title {
    font-weight: 400 !important;
    font-size: 14px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .event_text {
    margin-top: -90px !important;
  }
  .event_date {
    font-size: 12px !important;
  }
  .event_link_btn p {
    text-align: center !important;
  }
  .event_grid {
    padding-bottom: 45px !important;
  }
  .past_event_heading_img_box {
    margin-top: -10px !important;
    margin-left: 15.2% !important;
  }
  .past_event_heading_img {
    width: 135px !important;
  }
  .past_event_image_website {
    width: 100%;
    height: 240px !important;
    object-fit: cover;
  }
}

@media screen and (max-width: 600px) {
  .event_title {
    font-weight: 400 !important;
    font-size: 12px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .past_event_image_website {
    width: 100%;
    height: 230px !important;
    object-fit: cover;
  }
  .event_text {
    margin-top: -90px !important;
    padding-left: 10px;
  }
  .event_date {
    font-size: 10px !important;
  }
  .event_link_btn p {
    text-align: center !important;
  }
  .event_grid {
    padding-bottom: 45px !important;
  }
  .past_event_heading_img_box {
    margin-top: -10px !important;
    margin-left: 15.2% !important;
  }
  .past_event_heading_img {
    width: 100% !important;
  }
}
