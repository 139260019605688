.past_event_top_website{
    height: 60vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.past_event_selectbox_website{
    margin-top: 45px;
    width: 40%;
}

.past_event_selectbox_website p{
    font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 14px;
/* identical to box height, or 117% */

text-align: center;

/* Ochre/50 */

color: #FDFCFC;
}

.past_year_dropdown_website{
    width: 100%;
}

.past_year_dropdown_website .MuiFormControl-root{
    width: 100%;
}

.past_year_dropdown_website .MuiSelect-outlined{
    border: 1px solid #7B5418;
border-radius: 4px;
width: 100% !important;
font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 16px;
letter-spacing: 0.1px;
color: #BABDC2;
}

.past_year_dropdown_website .MuiOutlinedInput-root{
    width: 100%;
}

.past_year_dropdown_website .MuiSelect-icon{
    color: #BABDC2
}

.past_year_dropdown_website .MuiOutlinedInput-root:hover{
    border: 1px solid #7B5418;
}

.year_title_website{
    font-style: normal;
font-weight: 400;
font-size: 30px;
line-height: 40px;

/* Ochre/50 */

color: #FDFCFC;
font-family: 'Open Sans';
margin-bottom: 0px;
}

@media screen and (max-width:821px) {
    .past_event_selectbox_website{
        width: 100% !important;
    }
    .past_event_section{
        padding-top: 30px !important;
    }
    .event_grid_container{
        margin-top: 0px !important;
    }
}
@media screen and (max-width:600px) {
    .past_year_dropdown_website .MuiFormControl-root{
        width: 95% !important;
    }
}