.event_image_card{
    height: 120px;
    width: 100%;
    background-size: cover;
    border-radius: 8px;
    padding-bottom: 5px;
    position: relative;
    padding: 15px;
    cursor: pointer;
}

.event_management_image_card{
    height: 200px;
    width: 100%;
    background-size: cover;
    border-radius: 8px 8px 0px 0px;
    padding-bottom: 5px;
    position: relative;
    padding: 15px;
    cursor: pointer;
}

.image_item_grid{
    padding-top:0px !important;
}

.event_image_card_upload{
    height: 120px;
    width: 100%;
    border-radius: 8px;
    padding-bottom: 5px;
    position: relative;
    padding: 15px;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23903713FF' stroke-width='2' stroke-dasharray='6%2c 6' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
border-radius: 8px;
display: flex;
justify-content: center;
align-items: center;
cursor: pointer;
}

.event_image_card::before{
    content: '';
    background: rgba(0,0,0, 0.2);
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: 8px;
    top:0px;
    left: 0px;
    z-index: 0;
}

.event_management_image_card::before{
    content: '';
    background: rgba(0,0,0);
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: 8px 8px 0px 0px;
    top:0px;
    left: 0px;
    z-index: 0;
}

.event_detail_box{
    position: absolute;
    z-index: 1;
    width: calc(100% - 30px);
}
.event_management_detail_box{
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column !important;
    justify-content: space-between;
    align-items: flex-start;
}

.event_management_top_title{
    width: 100%;
}

.toggle_menu{
    background: #fff;
    border-radius: 8px;
    width:90px;
    height: 65px;
    top:10px;
    right: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 3px 8px rgba(0, 0, 0, 0.1);
}

.toggle_menu_right_pos{
    right: unset !important;
}

.toggle_menu ul{
    list-style: none;
    padding-left: 0px;
}

.toggle_menu ul li img{
    margin-right: 7.5px;
}

.event_management_artist_image{
    display: flex;
    justify-content: center;
}

.event_management_details{
    padding:10px;
    border-left: 1px solid #DDDDDD;
    border-right: 1px solid #DDDDDD;
    border-bottom: 1px solid #DDDDDD;
    border-radius: 0px 0px 8px 8px;
    min-height: 175px;
}

.event_management_status{
   bottom: 0px;
   position: absolute;
}

.event_status_live{
    background: 
    #89D883;
    color: #031F07;
    padding: 5px 10px;
    font-size: 10px;
    font-weight: 400;
    border-radius: 4px;
}

.event_status_scheduled{
    background: #FFE093;
    color: #031F07;
    padding: 5px 10px;
    font-size: 10px;
    font-weight: 400;
    border-radius: 4px;
}

.event_status_past{
    background: #F8BBBF;
    color: #031F07;
    padding: 5px 10px;
    font-size: 10px;
    font-weight: 400;
    border-radius: 4px;
}

.event_status_upcoming{
    background: 
    #CFF2CC;
    color: #031F07;
    padding: 5px 10px;
    font-size: 10px;
    font-weight: 400;
    border-radius: 4px;
}

.event_status_sub_type{
    background:#fdd670;
    color: #031F07;
    padding: 5px 10px;
    font-size: 10px;
    font-weight: 400;
    border-radius: 4px;
    margin-left: 6px;
    margin-top: 6px;
    padding-bottom: 0px;
    padding-top: 5px;
    height: 22px;
    width: 100px;
}

.event_status_type{
    background: 
    #cea669;
    color: #031F07;
    padding: 5px 10px;
    font-size: 10px;
    font-weight: 400;
    border-radius: 4px;
    margin-left: 6px;
    margin-top: 6px;
    padding-bottom: 0px;
    padding-top: 5px;
    height: 22px;
}

.event_status_cancelled{
    background: #F8BBBF;
    color: #031F07;
    padding: 5px 10px;
    font-size: 10px;
    font-weight: 400;
    border-radius: 4px;
}
.content-ellipsis{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    display: inline-block;
}