.artist_img_box {
  position: relative;
}
.hero_image_add_spacing {
  height: 80% !important;
  width: 80% !important;
  /* padding-left: 88px; */
  /* padding-top: 81px; */
}
.MuiPaper-elevation {
  box-shadow: none !important;
}
.hero_image_add_spacing .space_top_mobile .artist_image_website_box::before {
  height: 93%;
  width: 93%;
}
.hero_image_add_spacing .MuiGrid-grid-sm-12 .artist_image_website_box::before {
  height: 93%;
  width: 97%;
}
.hero_image_add_spacing .space_top_mobile .artist_image_website_box img {
  border-radius: 1000px 1000px 0px 0px;
  padding: 12.5px;
  position: relative;
  width: calc(100% - 45px);
}

.artist_img_box img {
  border-radius: 1000px 1000px 0px 0px;
}

.artist_box::before {
  content: "";
  background: url("./images/border.svg");
  height: 100%;
  width: 100%;
  position: absolute;
  background-repeat: no-repeat;
  background-size: contain;
}

.artist_preview_line::before {
  background: url("./images/outline.svg") !important;
}

.artist_box::after {
  content: "";
  background: linear-gradient(180deg, rgba(20, 10, 10, 0) 50%, #140a0a 104%);
  height: 100%;
  width: 90%;
  position: absolute;
  background-repeat: no-repeat;
  background-size: contain;
  top: 0px;
  /* background-color: #fff; */
}

.space_top {
  margin-top: 90px !important;
}

.artist_name {
  color: #fff;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  margin-bottom: 0px;
}

.artist_details {
  margin-top: -90px;
  text-align: center;
}

.artist_type {
  color: #fff;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
}

.artist_image_six {
  padding-left: 0px !important;
}
.css-ajmocp-MuiGrid-root {
  margin-top: 10px !important;
}
.css-1yxgp4-MuiGrid-root {
  margin-top: 56px !important;
}

@media screen and (max-width: 821px) {
  .space_top {
    margin-top: 0px !important;
  }
  .space_top_mobile {
    /* margin-top: 90px !important; */
    z-index: 99999;
  }
}
@media screen and (max-width: 600px) {
  .hero_image_add_spacing
    .MuiGrid-grid-sm-12
    .artist_image_website_box::before {
    height: 93%;
    width: 93%;
  }
  .artist_image_website_box img {
    border-radius: 1000px 1000px 0px 0px;
    padding: 11.5px;
    position: relative;
    width: calc(100% - 20px);
  }
  .welcome_section {
    position: relative;
    /* padding-top: 388px !important; */
  }
  .carousel_hero_banner > div > div {
    /* position: absolute !important; */
  }
  .hero_image_add_spacing .space_top_mobile {
    padding-left: 0;
    padding-top: 81px;
  }
  .hero_section_web_carousel {
    /* padding-top: 53px; */
  }
  .hero_section_web_carousel .hero_heading {
    font-size: 32px !important;
  }
  .MuiBox-root .hero_button_image {
    width: 268px;
    right: 27px;
    margin-top: -65px !important;
  }
  .space_top {
    margin-top: 0px !important;
  }
  .space_top_mobile {
    /* margin-top: 90px !important; */
    z-index: 99999;
  }
}
@media screen and (max-width: 480px) {
  .hero_section_web_carousel .carousel_hero_banner {
    height: 100% !important;
  }
}
@media screen and (max-width: 320px) {
  .MuiBox-root .hero_button_image {
    width: 268px;
    right: -20px;
    margin-top: -65px !important;
  }
}
@media screen and (max-width: 280px) {
  .hero_image_add_spacing .space_top_mobile .artist_image_website_box img {
    width: calc(100% - 31px);
  }
  .MuiBox-root .hero_button_image {
    margin-top: -67px !important;
    right: 0;
    width: auto;
    margin: 0;
  }
  .MuiBox-root .hero_button_image img {
    margin: 0;
    width: 100px;
  }
}
/* .artist_img_single{
    text-align: center;
    position: relative;
}
.artist_img_single .artist_image_website_box img {
    width: calc(100% - 388px) !important;
}
.artist_img_single .artist_image_website_box::before{
    left: 24%;
} */
/* .artist_img_single .artist_image_website_box::before {
    height: 93%;
    width: 45% !important;
}
.artist_img_single .artist_image_website_box{
    position: absolute;
    width: 100%;
    right: 0;
    top: -238px;
    left: 221px;
}
.artist_img_single  .artist_image_name_wrapper{
    width: 300px;
    position: absolute;
    top: 219px;
    left: 220px;
}
 .artist_img_single .artist_image_website_info p{

    position: absolute;
    top: 184px;
    left: 36px;

} */
