.announcement_top_website {
    padding-top: 150px;
    padding-bottom: 60px;
}

.announcement_header {
    display: flex;
    align-items: center;
    gap: 50px;
    margin-left: -18px;
}

.announcement_header button {
    color: #FFFFFF;
    border: none;
}

.announcement_header button:hover {
    border: none;
    background-color: #140A0A;
}

.announcement_heading {
    font-size: 20px !important;
    color: #FFFFFF !important;
}

.announcement_date {
    font-size: 16px !important;
    color: #BABDC2 !important;
    margin: 22px 0px 22px 95px !important;
}

.announcement_wrapper_message {
    padding: 0px 17px 150px 17px;
}

.announcement_message {
    color: #BABDC2 !important;
    font-size: 14px !important;
    padding: 79px;
}

@media (max-width: 600px) {
    .announcement_header {
        position: relative;
    }

    .announcement_header button {
        z-index: 10;
        top: -122px;
        position: absolute;
    }

    .announcement_header h5 {
        margin-left: 24px;
    }

    .announcement_date {
        margin-left: 6px !important;
    }
}