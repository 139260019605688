.content-centered {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  margin-left: -5%;
  background-image: url("./images/Banner.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.welcome-heading {
  font-family: "Open Sans" !important;
  font-size: 40px !important;
  font-weight: 400 !important;
  line-height: 54px !important;
  letter-spacing: 0em !important;
  text-align: center !important;
  color: #ffffff !important;
  margin-bottom: 10px !important;
}

.welcome-message {
  color: #babdc2 !important;
  padding-left: 25%;
  padding-right: 25%;
  /* font-family: Raleway !important; */
  font-family: "Inter";
  font-style: normal;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 19px !important;
  letter-spacing: 0.06800010055303574px !important;
  text-align: center !important;
  margin-bottom: 30px !important;
}
