.modal_form_popup .MuiDialog-paper{
    width: 32.5vw;
    padding: 22.5px 17.5px;
}

.modal_image_popup .modal_title{
    padding-bottom: 30px;
}
.btnPublishAnnoc{
    width: 60% !important;
}
.MuiDialogActions-root{
    justify-content: space-between !important;
    padding-left: 24px !important;
    padding-right: 24px !important;
}

.MuiDialogActions-root button{
    width: 60%;
}

.input_grid_display{
    width: 45%
}

.modal_form_popup_checkbox{
    display: flex;
    align-items: center;
    margin-left: -9px;
}

.modal_form_popup_checkbox .Mui-checked{
    color: #913713 !important;
}

.modal_form_popup_checkbox span{
    font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 16px;
/* identical to box height, or 114% */

letter-spacing: 0.1px;

color: #503B35;
}
.ql-editor
{
    min-height: 320px;
}

@media screen and (max-width: 821px) {
    .modal_form_popup .MuiDialog-paper{
        width: 100vw;
        padding: 22.5px 0px;
    }
}

@media screen and (max-width: 767px) {
    .input_grid_display{
        width: 100%
    }
}