.ticket_grid_website{
    display: flex;
    flex-direction: column !important;
    padding-left: 10% !important;
    padding-right: 10%;
}

.ticket_title_website{
    color: #BABDC2;
    font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 18px;
text-align: center;
height: 68px;
margin-bottom: 10px;
}

.ticket_label_website{
    font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 17px;
line-height: 22px;
text-align: center;

/* Grey/200 */

color: #E8EAED;
}

.ticket_price_website{
    font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 21px;
line-height: 24px;
text-align: center;

/* Grey/200 */

color: #E8EAED;
margin-top: 30px;
}

.ticket_title_online_website{
    border-bottom: 1px solid #9FA1A6;
    padding-bottom: 30px;
}

.ticket_titl_gate_website{
    margin-top: 30px;
}

@media screen and (max-width:821px) {
    .ticket_grid_website{
        padding-left: 2.5% !important;
        padding-right: 2.5% !important;
    }
    .upcoming_events_buy_ticket_box{
        padding-top: 120px !important
    }
    .ticket_title_website{
        font-weight: 400 !important;
font-size: 10px !important;
    }
    .ticket_label_website{
        font-size: 12px !important;
        font-weight: 600 !important;
    }
    .ticket_price_website{
        margin-bottom: 10px !important;
        margin-top: 10px !important;
    }
    .upcoming_events_buy_grid{
        margin-left: -16px !important;
    }
}
.bgPng{
    position: relative;
 }
 .bgPng .childwww {
     width: 100%;
 position: absolute;
 top: -83px;
 opacity: 0.5;
 right: 0;
 left: 0;
   }