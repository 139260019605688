.ticketBooking_top_website {
  padding-top: 50px;
  /* padding-bottom: 60px; */
  display: flex;
  flex-direction: row;
  }

.ticketBooking_top_website > .back_button {
  align-items: flex-start;
  margin-top: 24px;
  margin-left: -16px;
  border: none;
  color: #fdfcfc;
}

.ticketBooking_top_website > .back_button:hover {
  border: none;
  background-color: #140a0a;
}

.ticketBooking {
  flex: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ticketBooking .heading {
  font-weight: 400;
  font-size: 60px;
  font-family: "Open Sans";
  margin: 0px;
  line-height: 58px;
  color: #ffffff;
}

.ticketBooking .heading_line {
  margin-top: 8px;
  width: 100%;
}

.ticketBooking > .info {
  display: flex;
  gap: 20px;
}

.ticketBooking .address {
  word-break: break-all;
  color: rgba(255, 255, 255, 0.8);
  font-family: "Raleway";
  font-weight: 400;
  font-size: 16px;
  text-decoration: none;
}

.ticketBooking .time {
  color: rgba(255, 255, 255, 0.8);
  font-family: "Raleway";
  font-weight: 400;
  font-size: 16px;
}

.booking_wrapper {
  margin-top: 45px;
  display: flex;
  gap: 8px;
  max-width: 348px;
}

.booking_wrapper .counter {
  font-family: Raleway;
  font-weight: 700;
  font-size: 22px;
  height: 42%;
  margin-top: 46px;
}

.input_wrapper .MuiTypography-root {
  color: rgba(253, 252, 252, 1) !important;
  display: flex;
  justify-content: center !important;
}

.input_wrapper .MuiInputBase-root {
  background-color: #140a0a;
  color: #ffffff !important;
  border: thin solid #ffffff;
}

.input_wrapper input[type="number"]::-webkit-inner-spin-button,
.input_wrapper input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input_wrapper .MuiInputBase-input {
  text-align: center;
}

.input_wrapper .Mui-focused,
.input_wrapper .fieldset {
  border-color: #cfa669;
}

.wrapper_button {
  width: 24%;
  margin-top: 45px;
}

.wrapper_button .proceed_button {
  font-family: Raleway;
  font-weight: 700;
  font-size: 14px;
  height: 42px;
  display: block;
  width: 100%;
}

.wrapper_button .Mui-disabled {
  border: 1px solid rgba(255, 255, 255, 0.5) !important;
  color: #fff !important;
  cursor: pointer !important;
}
/* .image_container
{
  position: relative;
}
.image_one
{
    position: absolute;
    top: 0%;
    left: 0%;
}
.image_two
{
    position: absolute;
    right: 0%;
    top: 0%;
}
.image_three
{
    position: absolute;
    bottom: 0%;
    left: 0%;
}
.image_four
{
    position: absolute;
    bottom: 0%;
    right: 0%;
} */
@media (max-width: 478px) {
  .wrapper_button {
    width: 90% !important;
  }

  .input_wrapper .MuiTypography-root {
    margin-bottom: 4px;
  }
}

@media screen and (min-width: 200px) and (max-width: 640px) {
  .ticketBooking_top_website {
    position: relative;
  }

  .ticketBooking_top_website > .back_button {
    position: absolute;
    top: 2px;
    z-index: 1000;
  }

  .wrapper_button {
    width: 90%;
  }

  .input_wrapper .MuiTypography-root {
    margin-bottom: 3px;
  }
}

@media (max-width: 900px) {
  .wrapper_button {
    width: 48%;
  }
}
@media (max-width: 600px) {
  .ticketBooking .heading {
    text-align: center;
    font-size: 33px;
    line-height: 39px;
  }
}
