.search_input .MuiOutlinedInput-root fieldset{
    border: 1px solid #FAE4DB;
}

.search_input .MuiOutlinedInput-root .MuiInputBase-input{
    padding-left: 30px;
}

.search_input .MuiInputLabel-root{
    top: -3px !important
}

.search_input{
    width: 350px;
    height: 50px;
}

@media screen and (max-width: 768px){
    .search_input{
        /* width: 55% !important; */
        width: 100% !important;
    }
    .membership-top-bar{
        margin-left: 5px !important;
    }
}
.membership-top-bar{
    height: 44px;
}