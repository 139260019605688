.attendeedetails_top_website {
  padding-top: 150px;
  padding-bottom: 60px;
  /* background-color: red; */
  background-image: url(./images/Banner.png);
  background-size: 100% 100%; 
  background-repeat: no-repeat;
  
}

.attendeedetails_wrapper {
  display: flex;
  align-items: center;
  gap: 48px;
}

.attendeedetails_wrapper .back_button {
  color: #d5d7db;
  border: none;
}

.attendeedetails_wrapper .back_button:hover {
  background-color: #140a0a;
  border: none;
}

.attendeedetails_wrapper .MuiTypography-root {
  color: #d5d7db;
  font-size: 18px;
}

.attendeedetail_inputs {
  margin-left: 112px;
  margin-top: 45px;
}

.attendeedetail_inputs .attendeedetail_inputs_heading {
  color: #d5d7db;
  font-size: 16px;
}

.checkbox_wrapper {
  display: flex;
  align-items: center;
  margin-left: -12px;
}

.checkbox_wrapper .MuiTypography-root {
  color: #d5d7db;
  font-size: 14px;
  font-weight: 100;
}

.review_card {
  background-color: rgba(34, 23, 20, 1) !important;
  padding: 50px 30px 40px 30px;
  margin-top: -60px;
}
.review_card .artist_image_website_info h4{
  left: 15px;
  -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    font-size: 16px;
    line-height: 19px;
}

.card_title .MuiTypography-root {
  color: #d5d7db;
  font-size: 16px;
  font-weight: 400;
}

.card_content {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

.no_of_tickets {
  display: flex;
  margin-right: -9px;
}
.card_content_item_title {
  color: #d5d7db !important;
  font-size: 14px !important;
  font-weight: 200 !important;
  margin-bottom: 15px !important;
}

.card_content_item_total {
  color: #d5d7db !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  margin-top: 26px !important;
  margin-bottom: 15px !important;
}

.card_content_right {
  text-align: end;
}

.divider {
  background-color: #ffffff;
  width: 95%;
  margin-left: 16px !important;
}

.info_icon {
  color: #d5d7db !important;
  font-size: 18px !important;
}

.review_image {
  width: 95% !important;
  margin-left: 16px;
  margin-top: 10px;
}

.pay_button {
  width: 100%;
  border: 1px solid #ffffff !important;
  color: #ffffff !important;
  cursor: pointer;
}

.review_card .Mui-disabled {
  border: 1px solid #d5d7db !important;
  color: #d5d7db !important;
  cursor: not-allowed !important;
}

@media (max-width: 478px) {
  .next_button {
    width: 78% !important;
  }
}

@media (max-width: 280px){
  .next_button {
    width: 68% !important;
}
}

@media (max-width: 600px) {
  .review_card .artist_image_website_info h4 {
    left: -6px;
    font-size: 20px !important;
}
  .attendeedetails_top_website {
    padding-top: 130px;
    padding-bottom: 60px;
  }

  .attendeedetails_wrapper {
    margin-left: 110px;
    position: relative;
  }

  .attendeedetails_wrapper .back_button {
    position: absolute;
    top: -104px;
    left: -24px;
    color: #d5d7db;
    border: none;
    z-index: 1000;
  }

  .attendeedetails_wrapper .back_button:hover {
    background-color: #140a0a;
    border: none;
  }

  .review_card {
    background-color: #140a0a !important;
    padding: 0px 0px 40px 0px;
    margin-top: -60px;
  }

  .next_button {
    width: 82%;
    margin-left: 112px !important;
    margin-top: 30px !important;
    border: 1px solid #ffffff !important;
    color: #ffffff !important;
    cursor: pointer;
  }
}
