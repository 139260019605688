.welcome_section {
  padding-top: 0px;
  padding-bottom: 0px;
}

.welcome_text {
  color: #fff;
  font-weight: 400;
  font-size: 60px;
  text-align: center;
  margin-bottom: 0px;
}

.welcome_title {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  color: #fff;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.welcome_heading_img_box {
  margin-top: -2px !important;
}

.welcome_content_heading {
  display: none;
}

.line_img_box {
  width: 100%;
  text-align: center;
}

.welcome_section .flower_image_website_top,
.welcome_section .flower_image_website_bottom {
  display: none;
}

.welcome_content {
  margin-top: 30px;
}

.welcome_left_content {
  color: #fff;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  text-transform: uppercase;
  padding-right: 20%;
  margin-top: 0px;
}

.welcome_right_content {
  color: #fff;
  font-family: "Raleway";
  font-style: normal;
  line-height: 21px;
  font-weight: 300;
  font-size: 14px;
  margin-top: 0px;
}

.welcome_span_bold {
  font-weight: 900;
}
.save_icon
{
    background-color: grey;
    padding: 5px;
    border-radius: 50%;
    position: fixed;
    bottom: 10px;
    right: 10px;
    z-index: 1;
    opacity: 1;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}
.input_tag_right
{
  text-decoration: none;
  border: none;
  background-color:  transparent;
  /* outline: none; */
  /* color: white; */
  width: 100%;
  max-width: 100%;
  height: auto;
  transition: all 0.2s ease;
  resize: none;
  /* white-space: wrap; */
  /* text-wrap: wrap; */
  font-family: "Raleway";
  font-style: normal;
  line-height: 21px;
  font-weight: 300;
  font-size: 14px;
  overflow: hidden;
  color: white;
  /* or 114% */
  
  /* text-align: center; */
  letter-spacing: 0.1px;
}


@media screen and (max-width: 821px) {
  .welcome_content_grid {
    padding-top: 15px !important;
  }

  .welcome_left_content {
    font-weight: 600 !important;
    font-size: 14px !important;
    padding-right: 0px !important;
    line-height: 23px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #fdfcfc;
    opacity: 0.8;
  }

  .welcome_right_content {
    font-weight: 300 !important;
    font-size: 14px !important;
  }

  .welcome_heading_img_box {
    margin-top: -10px !important;
    margin-left: 15.2% !important;
    margin-bottom: 74px;
  }

  .welcome_heading_img {
    width: 114px !important;
  }
}

@media screen and (max-width: 600px) {
  .welcome_subtitle {
    display: none;
  }
 
  .welcome_content_heading {
    margin-top: -8px !important;
    display: flex;
    font-family: "Raleway";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #fdfcfc !important;
  }

  .welcome_section .flower_image_website_top {
    display: flex;
    width: 100px !important;
    position: absolute;
    top: 976px;
  }

  .welcome_section .flower_image_website_bottom {
    display: flex;
    width: 100px !important;
    position: absolute;
    right: 25px;
    top: 216%;
    /* top: 1164px; */
  }
}
/* @media screen and (max-width: 480px) {
  .mhhhhh
  {
    margin-top: 0px;

  }
} */


@media screen and (max-width: 900px) {
  .welcome_section {
    /* padding-top: 66px !important; */
  }

}

