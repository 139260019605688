.membership_section{
    padding-top: 0px;
    padding-bottom: 60px;
    margin-top: 0px !important;
}

.membership_content{
    /* background: url("images/rangoli.svg");
    background-repeat: no-repeat;
    background-size: contain;
    display: flex;
    flex-direction: column !important;
    align-items: center; */
}
.membership_content::after{
    /* content: url('images/rangoli.svg');
    width: 100%;
    height: 100%; */
    content: "";
width: 30px;
height: 30px;
/* background: url("/images/rangoli.svg") no-repeat -30px -50px no-scroll; */
/* background-color: red; */
top: 10px;
right: 5px;
position: absolute;
display: inline-block;
}

.rotate_diya{
    transform: scaleX(-1);
}

.membership_timeline{
    font-family: 'Raleway';
font-style: normal;
font-weight: 400;
font-size: 16px;
color: #fff;
text-align: center;
}

.membership_details{
    margin-top: 90px
}

.membership_price{
    font-family: 'Open Sans';
font-style: normal;
font-weight: 400;
font-size: 40px;
color: #fff;
text-align: center;
margin-top: 15px;
margin-bottom: 15px;
}

.membership_price span{
    font-family: 'Raleway';
font-style: normal;
font-weight: 700;
font-size: 40px;
}

.membership_passes{
    font-family: 'Raleway';
font-style: normal;
font-weight: 400;
font-size: 14px;
text-align: center;
color: rgba(255, 255, 255, 0.7);
}

.membership_button{
    width: 100%;
    text-align: center;
    margin-top: 90px;
}
/* #short_image1
{
    height: 60%;
}
#short_image
{
    height: 60%;

} */

@media screen and (max-width: 821px) {
    .diya_image{
        width: 125%;
    }
    .membership_details{
        margin-top: 45px !important;
    }
    .membership_timeline{
        font-size: 14px !important;
        font-weight: 400 !important;
    }
    .membership_price{
        font-size: 24px !important;
    }
    .membership_price span{
        font-size: 24px !important;
    }
    .membership_passes{
        font-size: 12px !important;
    }
    .membership_button{
        margin-top:45px !important
    }
}

@media screen and (max-width: 1024px) {
    .membership_section{
        padding-top: 0px;
        padding-bottom: 60px;
    }

}
@media screen and (min-width: 900px) {
    .membership_section{
        padding-top: 0px;
        padding-bottom: 60px;
    }

}
@media screen and (max-width: 600px) {
    .membership_section{
        padding-top: 0px;
        padding-bottom: 60px;
        margin-top: 0px !important;
    }

}
