.artist_detail_info_website {}




.view-more-link {
    text-decoration: underline;
    cursor: pointer;
}
.custom-content p{
    background-color: transparent !important;
}
.custom-content a {
    color: white;
    font-weight: 500;
}
.custom-content p {
    color: white !important;
}
.custom-content span {
    color: white !important;
    background-color: transparent !important;
}
.custom-content ul {
    color: white !important;
}
.custom-content ul li {
    color: white !important;
}

.custom-content h2,h4 {
    color: white !important;
}
.artist_detail_info_website h4 {
    font-weight: 400;
    font-size: 30px;
    line-height: 40px;
    color: #fff;
    font-family: 'Open Sans';
    display: flex;
    align-items: center;
    margin-top: 0px;
    margin-bottom: 0px;
    overflow: hidden;
    /* width: 15ch;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis !important; */
}

/* .artist_detail_info_website h4:hover {
    font-weight: 400;
    font-size: 30px;
    line-height: 40px;
    color: #fff;
    font-family: 'Open Sans';
    display: flex;
    align-items: center;
    margin-top: 0px;
    margin-bottom: 0px;

    
    white-space: unset;
    overflow: unset;
    text-overflow: unset;
    max-width: unset;
} */


.artist_detail_info_website h4 span {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    margin-left: 15px;
}

.artist_detail_info_website p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 24px;


    color: rgba(255, 255, 255, 0.9);
}

.artist_detail_bio_website p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 26px;
    /* or 153% */


    /* color: rgba(255, 255, 255, 0.9); */
    color: white !important;
    display: block !important;
}

.artist_detail_grid_website {
    padding: 45px 10%;
}

.artist_detail_section_website {
    padding-top: 60px;
    padding-bottom: 60px;
}

.artist_heading_box_website {
    margin-bottom: 30px;
}

.artist_detail_bio_expand {
    display: none !important;
}


@media screen and (max-width:821px) {
    .artist_detail_image_mobile .artist_image_website_box {
        width: 60% !important;
        margin-left: 20% !important;
    }

   
    .artist_detail_info_website img {
        width: 80% !important;
        margin-left: 10% !important;
    }

    .artist_detail_bio_website {
        display: none !important;
    }

    .artist_detail_bio_expand {
        display: block !important;
    }

    .artist_detail_bio_expand p {
        font-family: 'Inter';
        font-weight: 400;
        font-size: 14px;
        letter-spacing: 0.1px;
        color: #BABDC2;
    }

    .artist_detail_bio_expand span {
        color: white !important;
    }

    .artist_detail_grid_website {
        padding: 45px 10% 90px 10% !important;
    }
}