.memberships_section {
    /* padding-top: 100px; */
    padding-top: 50px;
    padding-bottom: 50px;
}


.membership_card_wrapper {
    padding: 0px 17px 150px 17px;
}

.membership_card {
    background-color: #140A0A !important;
    text-align: center;
    padding: 0px;
    box-shadow: none !important;
}

.membership_card:hover {
    background-color: rgba(255, 255, 255, 0.05) !important;
    border-radius: 20px;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    cursor: pointer;
}

.membership_card_content {
    padding-right: 0px !important;
    padding-left: 0px !important;
    
}

.membership_plan {
    color: #FAE4DB !important;
    font-size: 24px !important;
    font-weight: 400 !important;
    line-height: 40px !important;
    font-family: 'Inter' !important;
}

.membership_prices {
    font-family: 'Inter' !important;
    font-weight: 500 !important;
    font-style: normal !important;
    color: #FDFCFC !important;
    font-size: 42px !important;
}

.membership_duration,
.membership_list_feature {
    font-family: Inter !important;
    font-weight: 400 !important;
    font-style: normal !important;
    color: #7E8085 !important;
    font-size: 14px !important;
    letter-spacing: 0.1 !important;
}

.membership_description {
    font-family: Inter !important;
    font-weight: 400 !important;
    font-style: normal !important;
    color: #7E8085 !important;
    font-size: 14px !important;
    letter-spacing: 0.1 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 0.7px solid #7E8085;
    border-bottom: 0.7px solid #7E8085;
    margin-top: 20px !important;
    margin-bottom: 20px !important;
    padding: 20px;
    min-height: 105px;
}

.membership_list {
    padding: 4px 35px 4px 35px !important;
    align-items: center !important;
}

.membership_list_icon {
    color: #7E8085;
    font-size: 14px !important;
    background-color: white;
    border-radius: 50%;
}

.subscription_button {
    color: white !important;
    border-color: white !important;
    margin-top: 20px !important;
    font-size: 14px !important;
}

.subscription_button:hover {
    color: white !important;
    border-color: #140A0A !important;
}

@media (max-width: 600px) {
    .subscription_button {
        width: 80% !important;
    }
}

@media (max-width: 900px) {
    .subscription_button {
        width: 80% !important;
    }
}
@media (max-width: 992px) {
    .membership_description {
        min-height: 127px;
    }
    .membership_list {
        min-height: auto;
    }
}
@media (max-width: 1024px) {
    .membership_list {
        min-height: 48px;}
}