.thank_you_box{
    height: 100vh;
    display: flex;
    flex-direction: column !important;
    justify-content: center;
    align-items: center;
}

.thank_you_box h4{
    font-family: 'Open Sans';
font-style: normal;
font-weight: 400;
font-size: 40px;
line-height: 54px;
/* identical to box height */

display: flex;
align-items: center;
text-align: center;
text-transform: capitalize;

/* Ochre/50 */

color: #FDFCFC;
margin-top: 0px;
margin-bottom: 15px;
}

.thank_you_box p{
    font-family: 'Raleway';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 19px;
/* identical to box height */

text-align: center;
letter-spacing: 0.0680001px;

color: rgba(255, 255, 255, 0.8);
margin-top: 0px;
margin-bottom: 30px;
}