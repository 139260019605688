.header {
  margin-bottom: 30px;
}

.wrapper_banner_button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.wrapper_banner button {
  margin-top: 15px;
  border: 1px solid #ffdad9;
}

.wrapper_scheduler {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 50px;
  /* min-height: 190px; */
}

.wrapper_date input {
  width: 180px;
}

#timeBox1,
#timeBox2 {
  top: 94px;
  right: 0px;
}

.scheduler_buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.scheduler_buttons button {
  width: 44%;
}

.admin_banner_grid_item{
  border: 1px solid;
}

.admin_banner_grid_box{
  border:1px solid #D5D7DB;
  border-radius: 8px;
}