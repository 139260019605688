.buy_cd_detail_para_box p{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.8);
    text-align: center;
    line-height: 18px;
}

.buy_cd_detail_para_box{
    padding-left: 20%;
    padding-right: 20%;
}

@media screen and (max-width:821px) {
    .buy_cd_detail_para_box{
        padding-left: 5% !important; 
    padding-right: 5% !important;
    }
}