.textarea_input {
  font-family: "Inter";
  margin-top: 10px;
  resize: none;
  border-color: rgba(0, 0, 0, 0.23);
  font-size: 16px;
  padding: 16.5px 14px;
  border-radius: 4px;
  width: 95%;
}

@media screen and (max-width: 767px) {
  .textarea_input {
    font-size: 10px;
    width: 91%;
  }
}
@media screen and (max-width: 280px) {
  .textarea_input {
    font-size: 10px;
    width: 88%;
  }
}
