.decoration_section {
  position: relative;
  /* margin-top: 200px;
  margin-left: 20px;
  margin-right: 30px;
  padding-top: 50px; */
  width: 96% !important;
  height: auto;
  margin: 20px;
  }

.decor {
  position: absolute;
}

.decor1 {
  left: 0px;
  top: 0px;
}

.decor2 {
  left: 0px;
  bottom: 0px;
  transform: rotate(180deg) scaleX(-1);
}

.decor3 {
  right: 0px;
  top: 0px;
  transform: rotate(90deg);
}

.decor4 {
  right: 0px;
  bottom: 0px;
  transform: rotate(180deg);
}


@media (max-width:400px) {
  .decoration_section {
    position: relative;
    /* margin-top: 200px;
    margin-left: 20px;
    margin-right: 30px;
    padding-top: 50px; */
    width: 86% !important;
    height: auto;
    margin: 20px;
    }
}