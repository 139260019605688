.buy_cd_wizard_page{
    /* padding-top: 120px; */
    padding-top: 50px;
    padding-bottom: 60px;
}

.buy_cd_wizard_steps .wizard_step.wizard_step_active .wizard_title{
    color: #D5D7DB;
}

.buy_cd_wizard_steps .wizard_step.wizard_step_active .wizard_option_border{
    border-color: #D0931B !important;
}

.buy_cd_wizard_steps .wizard_step .wizard_title{
    color: #7E8085;
}

.buy_cd_steps_box{
    min-height: 70vh;
}

.wizard_step_option{
    position: relative;
    height: 100%;
}

@media screen and (max-width:821px) {
    .wizard_step .wizard_option_border{
        position: absolute !important;
        width: 100% !important;
        bottom: 25px !important;
    }
}