.buy_cd_top{
    padding-top: 150px;
    padding-bottom: 60px;
    padding-left: 20%;
    padding-right: 20%;
}

.buy_cd_top_para1{
    font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 14px;
text-align: center;
letter-spacing: 0.1px;
color: #D5D7DB;
margin-top: 45px;
}

.buy_cd_top_para2{
    font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 14px;
text-align: center;
letter-spacing: 0.1px;
color: #7E8085;
margin-top: 30px;
}

.buy_cd_select_box{
    display: flex;
    flex-direction: column !important;
    justify-content: center;
    align-items: center;
    padding-bottom: 60px;
}

.buy_cd_select_box .past_event_selectbox_website{
    margin-top: 0px;
}

.buy_cd_price_box h4{
    font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 24px;
color: #606266;
margin-top: 15px;
margin-bottom: 30px;
}

.buy_cd_names{
    padding-top: 60px;
    padding-bottom: 60px;
}

.buy_cd_grid{
    padding-top: 0px !important;
    display: flex;
    flex-direction: column !important;
    justify-content: center;
    align-items: center;
}

.buy_cd_grid img{
    width: 40%;
}

.buy_cd_grid h4{
    font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 17px;
color: #FFFFFF;
margin-top: 30px;
margin-bottom: 0px;
}

.buy_cd_grid p{
    font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 14px;
color: #FFFFFF;
text-align: center;
}

.buy_cd_link{
    font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 14px;
color: #BABDC2;
text-decoration: none;
display: flex;
width: 100%;
justify-content: center;
align-items: center;
}

.buy_cd_link img{
    height: 10px;
    width: auto;
    margin-left: 10px;
}

.buy_cd_paypal_button{
    width: 40%;
}

@media screen and (max-width:821px) {
    .buy_cd_top{
        padding-left: 5% !important;
        padding-right: 5% !important;
    }
    .buy_cd_paypal_button{
        width: 60% !important;
    }
    .buy_cd_grid img{
        width: 80% !important;
    }
    .buy_cd_link img{
        width: auto !important;
    }
    .buy_cd_grid{
        padding-bottom: 60px !important;
    }
}