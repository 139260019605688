.login_dialog_website .MuiDialog-container .MuiDialog-paper{
    background-image: url('./images/decoration.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #140A0A;
    border-radius: 20px;
    width: 60%;
}

.login_dialog_website .MuiDialog-container .MuiDialog-paper::-webkit-scrollbar {
    width: 8px;
  }
  
  /* Track */
  .login_dialog_website .MuiDialog-container .MuiDialog-paper::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    background: #150a0a; 
    border-radius: 10px;
  }
   
  /* Handle */
  .login_dialog_website .MuiDialog-container .MuiDialog-paper::-webkit-scrollbar-thumb {
    background: #d0931c; 
    border-radius: 10px;
  }
  
  /* Handle on hover */
  .login_dialog_website .MuiDialog-container .MuiDialog-paper::-webkit-scrollbar-thumb:hover {
    background: #d0931c; 
  }

.login_dialog_subtitle{
    font-family: 'Raleway';
font-style: normal;
font-weight: 400;
font-size: 16px;
text-align: center;
letter-spacing: 0.0680001px;
color: rgba(255, 255, 255, 0.8);
/* margin-bottom: 45px; */
}

.login_dialog_content_box{
    padding: 15px 10% 60px 10%;
}

.login_dialog_actions{
    display: flex !important;
    flex-direction: row;
    justify-content: center !important;
    align-items: center !important;
}

.login_dialog_actions button{
    width: 100% !important;
}

.login_dialog_actions button.Mui-disabled{
   border: 1px solid rgba(255, 255, 255, 0.5) !important;
   color: #7E8085;
   cursor: not-allowed;
   pointer-events: auto;
}

.login_dialog_signup p{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    text-align: center;
    color: #7E8085;
    margin-top: 10px;
}

.login_dialog_signup_link{
    color: #fff;
    text-decoration: none;
    font-weight: 700;
}

.web_dialog_extra_subtitle{
    color: #fff;
    font-weight: 600;
}

.web_dialog_close_image{
    position: absolute;
    top: 20px;
    right: 20px;
}

.web_dialog_close_image img{
    cursor: pointer;
}

@media screen and (max-width: 820px) {
    .login_dialog_website .MuiDialog-container .MuiDialog-paper{
        width: 80% !important;
    }
}

@media screen and (max-width: 667px) {
    .login_dialog_website .MuiDialog-container .MuiDialog-paper{
        width: 100% !important;
    }
    .login_dialog_content_box{
        padding: 15px 0% 60px 0% !important;
    }
}