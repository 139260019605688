.admin_banner_image{
    height: 200px;
    width: 100%;
}

.admin_banner_content{
    padding:15px;
}

.admin_banner_event_times{
    display: flex;
    margin-top: 10px;
}

.admin_banner_event_date{
    width: 50%;
}