.gallery_section {
    padding-top: 0px;
    padding-bottom: 120px;
    /* background-color: #fff; */
    /* margin-left: 35px; */
    margin-top: 0px;
    align-items: center !important;
    justify-content: center !important;
    
}

.gallery_heading_img_box {
    margin-top: -36px !important;
    margin-left: 20px !important;
}

.gallery_heading_img {
    width: 88px;
}

.gallery_section_link_container {
    margin-top: 90px;
}

.gallery_section_link_btn a {
    text-decoration: none !important;
}

.gallery_section_link_btn a p {
    text-decoration: none !important;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.1px;
    color: #F8F9FA;
    text-align: center;
}

.gallery_section_link_btn a p img {
    margin-left: 10px;
}


@media screen and (max-width:821px) {
   
    .gallery_heading_img_box {
        margin-top: -18px !important;
        margin-left: 16.4% !important;
        background-color: #fff;
    }

    .gallery_heading_img {
        width: 56px !important;
    }   
}

