.main-block {
    color: rgba(255, 255, 255, 0.9);
    font-family: "Inter";
    text-align: justify;
}

.qna-block {
    padding: 60px;
    justify-content: space-between;
    padding-top: 10px;
}

.question-text {
    font-weight: 700;
    font-size: 17px;
    list-style-type: disc;
    padding-top: 10px;
    text-align: center;
}

/* .question-text::before {
    content: '\2022';
    margin-right: 10px;
} */

.answer-text {
    font-weight: 400;
    font-size: 17px;
}