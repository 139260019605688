.user_info_edit_dialog .MuiDialog-container .MuiDialog-paper{
    width: 370px;
    background: #221714;
/* CTA Shadow */

box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.08);
border-radius: 8px;
}

.user_info_edit_dialog_admin .MuiDialog-container .MuiDialog-paper{
    background: #FDFCFC !important;
}

.user_info_edit_dialog_heading{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.user_info_edit_dialog_heading h6{
    margin-top: 0px;
    margin-bottom: 0px;
    font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 21px;
line-height: 24px;
letter-spacing: 0.25px;
color: #D5D7DB;
}

.user_info_edit_dialog_heading_admin h6{
    color: #221714 !important;
}

.user_info_edit_dialog_subtitle{
    font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 16px;
letter-spacing: 0.1px;
color: #9FA1A6;
}

.user_info_edit_dialog_subtitle_admin{
    color: #606266 !important;
}

.user_info_edit_dialog_image_box{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 30px;
}

.user_info_edit_dialog_image{
    height: 120px;
    width: 120px;
    border-radius: 50%;
    object-fit: cover;
}

.user_info_edit_dialog_buttons{
    margin-top: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
}
@media screen and (max-width:821px) {
    .dddprofile{
        cursor: pointer;
        width: auto !important;
    }
    .dddprofile span {
        line-height: 17px !important;
    }
}
.upload_picture_label{
    cursor: pointer;
    width: 155px;
height: 42px;
border: 1px solid #FDFCFC;
border-radius: 8px;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
}

.upload_picture_label_admin{
    background: #913713 !important;
    border-color: #913713 !important;
}

.upload_picture_delete{

width: 103px;
height: 42px;

border-radius: 8px;
display: flex;
justify-content: center;
align-items: center;
}

.upload_picture_delete span {
    font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 12px;

color: #FDFCFC;
cursor: pointer;
}

.upload_picture_delete_admin span{
    color: #913713 !important;
}

.upload_picture_label span{
    font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 12px;
color: #FDFCFC;
}