.content_container{
    width: calc(100vw - 240px);
    margin-left: 240px;
    margin-top: 45px;
}

.content_inner_container{
    padding-left: 24px;
    padding-right: 24px;
}

@media screen and (max-width:767px) {
    .content_container{
        width: 100vw;
        margin-left: 0px;
        margin-top: 45px;
    }

    .header_right{
        width: 45%;
    }

    .content_inner_container{
        padding-left: 12px;
        padding-right: 12px;
    }
}