.loader_box{
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.4);
    position: fixed;
    top:0;
    left:0;
    z-index:9999999999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.loader-container {
    
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.4);
    position: fixed;
    top:0;
    left:0;
    z-index:9999999999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .loader {
    display: flex;
    align-items: center;
    /* animation: spin 2s linear infinite; */
    justify-content: center;
  }
  
  .custom-icon {
    /* Add your custom icon styles here */
    height: 80%;
    width: 80%;
    object-fit: contain;
  }
  
  /* @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  } */