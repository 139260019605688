.web_dialog_mail_join_btn button {
  width: 75%;
}

.web_dialog_mail_join_btn .Mui-disabled {
  border: 1px solid #d5d7db !important;
  color: #d5d7db !important;
  cursor: not-allowed !important;
}

.login_dialog_signup_link {
  cursor: pointer;
}

.login_dialog_actions {
  cursor: pointer;
}
