.contact_us_section_website{
    padding-top: 60px;
    padding-bottom: 60px;
}

.contact_us_title_website{
    font-family: 'Open Sans';
    font-style: normal;
font-weight: 400;
font-size: 60px;
line-height: 80px;
color: #fff;
}

.contact_us_title_website{
    text-align: center;
}

.contact_us_para_website{
    font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 17px;
line-height: 22px;
text-align: center;
color: #BABDC2;
padding-left: 10%;
padding-right: 10%;
margin-bottom: 30px;
}

.contact_info_grid{
    padding-left: 25%;
    padding-right: 25%;
    margin-top: 45px !important;
}

.contact_name_website{
    font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 21px;
line-height: 24px;

color: #F8F9FA;
margin-bottom: 0px;
margin-top: 0px;
}

.contact_email_website{
    font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 16px;
letter-spacing: 0.1px;

color: #F8F9FA;
}

.contact_border{
    border-right: 1px solid #F8F9FA;;
}

@media screen and (max-width:821px) {
    .contact_us_title_website{
        font-weight: 400 !important;
font-size: 32px !important;
    }
    .contact_us_para_website{
        font-weight: 400 !important;
font-size: 14px !important;
    }
    .contact_info_grid{
        padding-left: 10% !important;
        padding-right: 10% !important;
    }
    .contact_border{
        border-right: unset !important;
    }
    .contact_name_website{
        font-weight: 700 !important;
font-size: 17px !important;
    }
}