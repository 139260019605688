.all_artist_top_website{
    height: 60vh;
    display: flex;
    flex-direction: column !important;
    justify-content: center;
    align-items: center;
}

.all_artists_subtitle_website{
    font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 17px;
text-align: center;

/* Ochre/50 */

color: #FDFCFC;
margin-top: 45px;
padding-left: 25%;
padding-right: 25%;
}

.all_artist_section_website{
    padding-top: 60px;
    padding-bottom: 60px;
}

.all_artist_section_images_website .artist_image_website_info {
    margin:0px
}

.all_artist_section_heading_website{
    font-style: normal;
font-weight: 400;
font-size: 40px;
font-family: 'Open Sans';

color: #F8F9FA;
margin-top: 0px;
margin-bottom: 0px;
}
.all_artist_section_heading_website_image
{
    margin-top: -100px !important;
    position: relative;
    top: -20px;
}

@media screen and (max-width:821px) {
    .all_artist_top_website{
        height: 40vh !important;
        padding-top: 100px !important;
        /* margin-bottom: 100px; */
        /* background-color: #fff; */

    }
    .section_img_box img    {
        margin-top: 10px !important;
    }

    .section_heading
    {
      margin-bottom: 10px !important;
    }
    
  
    .all_artists_subtitle_website{
        padding-left: 5% !important;
        padding-right: 5% !important;
    }
    .all_artist_section_heading_website{
        text-align: center !important;
        font-size: 24px !important;
    }
    .all_artist_section_website .title_divider_image_mobile{
        margin-left: 35% !important;
    }
    .all_artist_section_heading_website_image
    {
        display: none;
    }
}