.footer_section {
  width: calc(100vw - 11%);
}
.socialWrap {
  display: flex;
  justify-content: flex-end;
}
.footer_options_list {
  list-style: none;
  margin-top: 0px;
}
.footer_options_li {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 15px;
  letter-spacing: 0.1px;
}
.footer_options_link {
  color: #9FA1A6;
  text-decoration: none;
}
.footer_mailing_button {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  /* background-color: red; */
}
.footer_contact_icons {
  margin-top: 18px;
  display: flex;
  /* align-items: center; */
  /* margin-left: 65px; */
  justify-content: space-between;
  width: 100%;
  /* background-color: #fff; */
}
.footer_options {
  border-bottom: 1px solid #E5767A;
  padding-bottom: 46px;
}
.footer_extra {
  padding-top: 45px;
  padding-bottom: 15px;
  border-top: 1px solid #E5767A;
}
.footer_copyright {
  color: #BFBFBF;
  font-family: "Helvetica Neue";
  font-style: normal;
  font-weight: 400;
  font-size: 13.545px;
}
.footer_copyright_para {
  font-family: "Helvetica Neue";
  font-style: normal;
  font-weight: 400;
  font-size: 13.545px;
  color: #BFBFBF;
  padding-bottom: 30px;
  margin-bottom: 0px !important;
  /* margin-top: 64px; */
}
.footer_powered_para {
  font-family: "Helvetica Neue";
  font-style: normal;
  font-weight: 400;
  font-size: 13.545px;
  color: #BFBFBF;
  padding-bottom: 30px;
  margin-bottom: 0px !important;
}
.mail_button span {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #140A0A;
}
.footer_contact_icons img
{
  height: 32px;
  width: 32px;
}
.footer_icons_button1
{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* background-color: grey; */
  width: 170px;
  height: 94px;
}
@media screen and (max-width: 821px) {
  .footer_contact_list {
    display: flex;
    flex-direction: column !important;
    align-items: center;
    justify-content: center;
    padding-left: 0;
  }
  .footer_contact_list .footer_mailing_button {
    /* justify-content: unset !important; */
    width: 100%;
    padding: 0% !important;
  }
  .footer_contact_icons {
    width: 100% !important;
    justify-content: space-between !important;
    /* margin-left: -155px !important; */
    /* background-color: grey; */
  }
  .footer_options_list {
    /* padding-left: 0px !important; */
  }
  .footer_logo_grid {
    padding-bottom: 30px;
  }
  .footer_extra {
    padding-top: 25px;
  }
}
@media (min-width: 1143px) {
  .footer_contact_icons {
    width: 100% !important;
    justify-content: space-between;
    /* margin-left: 80px !important; */
    /* background-color: #fff; */
  }
}
@media (min-width: 1250px) {
  .footer_contact_icons {
    width: 100% !important;
    justify-content: space-between;
    /* margin-left: 83px !important; */
    /* background-color: #fff; */
  }
}
@media (max-width: 896px) {
  /* .footer_grid {
      padding: 0;
    } */
  .socialWrap {
    display: flex;
    justify-content: flex-start;
  }
  .footer_options_list {
    padding: 10px;
  }
  .footer_section_grid_2,
  .footer_section_grid_3,
  .footer_section_grid_4 {
    margin-top: -54px !important;
  }
  .footer_mailing_button {
    justify-content: flex-start;
    margin-left: 26px;
  }
  .footer_contact_icons {
    justify-content: space-between;
    margin-left: 20px;
    width: 68%;
  }
  .footer_contact_list {
    padding-left: 0 !important;
  }
  .footer_options_li {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
  }
  .footer_options_link {
    color: #BFBFBF;
    text-decoration: none;
  }
}