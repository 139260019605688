.sort_dropdown_container {
  position: relative;
}

.sort_dropdown {
  position: absolute;
  background: #fff;
  width: 200px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 3px 8px rgba(0, 0, 0, 0.1);
  right: 0px;
  border-radius: 8px;
  margin-top: 10px;
  z-index: 99999;
}

.sort_dropdown ul {
  list-style: none;
  padding: 10px 10px;
  margin: 0px;
}

.sort_dropdown ul li {
  padding-left: 15px;
}

.sort_dropdown ul li:hover {
  background-color: #fcf2f2;
  cursor: pointer;
}

.topbar_text_details {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.btnCustomCss{
  height: 45px !important;
}
.topbar_buttons_box {
  justify-content: flex-end;
}

.topbar_search_box {
  margin-right: 15px;
}

.topbar_grey_total {
  color: #84828a;
}

.topbar-filter {
  padding: 8px 30px !important;
}

.filter_dropdown {
  padding: 15px;
  width: 220px;
  z-index: 999;
}

.filter_year_span {
  background: #fae4db;

  border: 1px solid #f4c2ae;
  border-radius: 4px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-align: center;

  color: #913713;
  padding: 5px 10px;
  margin-left: 10px;
  position: relative;
}

.filter_year_span img {
  position: absolute;
  top: -8px;
  right: -8px;
  background: #fff;
  width: 16px;
  height: 16px;
  padding: 3.5px;
  border-radius: 50%;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.06);
  cursor: pointer;
}

.filter_dropdown_top {
  padding-bottom: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 0.5px solid #e8eaed;
}

.select_filter_years fieldset {
  border: 1px solid #fae4db;
}

.select_filter_years .MuiOutlinedInput-input {
  padding: 0px !important;
  height: 45px !important;
}

.select_filter_years .MuiOutlinedInput-input {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 10px !important;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;

  /* Dark Grey/400 */

  color: #221714;
}

.select_filter_years .MuiOutlinedInput-input span {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;

  /* Dark Grey/400 */

  color: #221714;
}

.filter_dropdown_options_label {
  margin-top: 5px;
}

.multiple_options_buttons {
  display: flex;
  flex-direction: row;
}
  /* .multiple_options_buttons button {
    margin: 0 10px;
  
  } */

.multiple_options_buttons_margin {
  margin-right: 15px;
}

.multiple_options_no_margin {
  margin-right: 0px;
}
.downloadBtn{
  margin-left: 16px;
    margin-right: -14px;
}