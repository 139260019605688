.alert_content{
    display: flex;
    align-items: center;
}

.alert_content img{
    margin-right: 10px;
}

.alert_container{
    padding-left: 15px;
    padding-right: 15px;
    height: auto !important;
    min-height: 44px;
}

.alert_close_img{
    cursor: pointer;
}

.announcement_alert_position{
    position: fixed;
    top: 37.5px;
    z-index: 999999;
    display: flex;
    width: calc(100% - 240px);
    justify-content: center;
}

.website_alert_position{
    position: fixed;
    top: 90px;
    z-index: 999999;
    display: flex;
    width: 100%;
    justify-content: center;
}

.alert_position_container{
    width: 45%;
}

.web_alert_position_container{
    width: 20%;
}

.web_alert_position_container .alert_container{
    min-height: 48px !important;
    height: auto !important;
    padding-top: 10px;
    padding-bottom: 10px;
}
@media screen and (max-width: 670px) {
.web_alert_position_container {
    width: 80%;
}
.alert_position_container{
    width: 35%;
}
}