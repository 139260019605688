.tab_box{
    margin-top: 30px;
    margin-bottom: 30px;
    border: 1px solid #FFDAD9;
}
.tab_box .MuiTabs-scroller{
    margin: 0 20px;
}

.tab_item{
    padding-top:20px !important;
    padding-bottom: 20px !important;
    color: #000 !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    height: 56px !important;
}

.tab_item.Mui-selected{
    color: #913713 !important;
}

.tab_box_full_width .MuiTabs-flexContainer button{
    width: 33.33%;
}