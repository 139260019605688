.buy_cd_step2_address{
    background: #221714;
    /* padding: 30px; */
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.buy_cd_step2_box{
    padding-top: 60px;
    padding-bottom: 60px;
}

.buy_cd_step2_address_label{
    font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 16px;
margin-top: 0px;

color: #9FA1A6;
}

.buy_cd_step2_address_line1{
    font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 16px;

color: #D5D7DB;
}

.buy_cd_step2_address_line2{
    font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 16px;

color: #D5D7DB;
}

.buy_cd_step2_pencil_icon{
    height: 15px;
    cursor: pointer;
}

.buy_cd_step2_button_holder{
    margin-top: 60px;
    display: flex;
    justify-content: center;
}

.buy_cd_step2_edit_address{
    background: #221714;
    padding: 0px;
}

.buy_cd_step2_edit_address_top{
    display: flex;
    justify-content: space-between;
}

.buy_cd_step2_edit_buttons{
    margin-top: 45px;
    display: flex;
}

.buy_cd_step2_delete_button{
    margin-left: 60px;
}

.buy_cd_step2_delete_button{
    font-family: 'Raleway';
font-style: normal;
font-weight: 700;
font-size: 14px;
line-height: 16px;

color: #D5D7DB;
display: flex;
align-items: center;
cursor: pointer;

}

.buy_cd_step2_delete_button img{
    margin-right: 15px;
}

.buy_cd_step2_edit_address_box{
    margin-top: 30px;
    padding: 30px;
    padding-bottom: 60px;
    background: #221714;
}
.filter-grayscale {
    filter: grayscale(1)brightness(0.7);
}

.no-events-text {
    color: #9FA1A6;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; 
    letter-spacing: 0.15px;
    margin-bottom: 16px;
}
