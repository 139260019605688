.wizard_steps{

}

.wizard_step{
    cursor: pointer;
}

.wizard_step .wizard_title{
    color: #606266;
    font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 24px;
margin-bottom: 15px;
}

.wizard_step.wizard_step_active .wizard_title{
    color: #221714;
    font-weight: 700;
}

.wizard_step.wizard_done .wizard_title{
    color: #606266;
    font-weight: 400;
}

.wizard_step .wizard_option_border{
    border-top:2px solid #E8EAED;
    background: unset !important;
}

.wizard_step.wizard_step_active .wizard_option_border{
    border-top:2px solid #E5767A;
    height: 2px;
}

.wizard_step.wizard_done .wizard_option_border{
    border-top:2px solid #F9DFE0;
    height: 2px;
}

@media (max-width: 600px){
    .content_container {
        
        margin-top: 0px !important;
    }
    .event-date-icon{
        top:22px !important;
    }
    .event-clock-icon{
        top:34px !important;
    }
    .draft_btn{
        padding: 0 !important;
        margin-right: 5px !important;
    }
    .accordion_input_grid{
        padding-left:0px !important;
    }
}