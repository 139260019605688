.web_option {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* padding: 5px; */
  margin: 5px;
  white-space: nowrap;
}

.nav_link {
  color: #d5d7db;
  text-decoration: none;
}

.nav_text {
  font-weight: 400;
  font-family: "Inter", sans-serif;
  font-size: 14px !important;
}

.nav_text.active {
  font-weight: bold;
}

.web_header_user_dropdown {
  background: #2d2323;
  padding: 7.5px;
  border-radius: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* width: 150px; */
  position: relative;
  cursor: pointer;
}

.web_header_user_img {
  border-radius: 50%;
}

.web_user_image {
  height: 28px;
  width: 28px;
}

.web_header_user_dropdown p {
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  text-align: left;
  color: #fdfcfc;
  margin-left: 7.5px;
  margin-right: 15px;
  /* width: 80px; */
}

.web_header_dropdown {
  position: absolute;
  width: 165px;
  top: 50%;
  left: 0;
  background: #140a0a;
  z-index: -1;
  /* Dark Grey/300 */

  border: 1px solid #503b35;
  /* Shadow/300 */

  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 3px 8px rgba(0, 0, 0, 0.1);
  border-radius: 0px 0px 20px 20px;
  padding-top: 25px;
  padding-bottom: 10px;
}

.web_header_dropdown ul {
  padding-left: 25px;
  padding-right: 25px;
}

.web_header_dropdown ul li {
  margin-bottom: 20px;
  display: flex;
}

.web_header_dropdown ul li img {
  margin-right: 17.5px;
  width: 15px;
}

.web_header_dropdown ul li span {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.1px;

  color: #e8eaed;
}

.web_header_dropdown_link {
  text-decoration: none;
}
.username_elipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100px;
}
