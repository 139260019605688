.event_card_box_website {
    padding-top: 60px;
    padding-bottom: 60px;
    /* min-height: 100vh; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    /* min-height: 100vh; */
    /* width: 100vw; */
    margin-left: -5%;
    background-image: url('./images/Banner.png');
    background-size: cover;
    background-repeat: no-repeat;
}

.event_card_heading_website {
    font-family: 'Open Sans';
    font-weight: 400;
    font-size: 60px;
    color: #fff;
    margin: 0px;
    line-height: 60px;
    text-align: center;
    text-transform: capitalize;
}

.event_card_heading_span{
    position: relative;
}

.event_card_heading_span::after{
    background-image: url('./images/divider.svg');
    position: absolute;
    content: "";
    background-repeat: no-repeat;
    background-size: contain;
    height:35px;
    width: 100%;
    left: 0px;
    bottom: -31px;
    display: none;
}

.event_card_info_website {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.event_card_info_website img {
    margin-right: 15px;
}

.event_card_info_box_website {
    margin-top: 45px;
}

.event_card_info_para_website {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #FFFFFFCC;
    text-decoration: none;

}

.event_card_buttons_website {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
}

.event_card_link_website {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #fff;
    text-decoration: none;
    margin-left: 20px;
    margin-top: 20px;
}

.event_card_link_website img {
    margin-left: 10px;
}

.event_card_artists_website {
    margin-top: 30px;


}

.event_card_artists_website1 {
    width: 15%;
    
}

.event_card_artists_website2 {
    width: 35%;
}

.event_card_artists_website3 {
    width: 65%;
}

.event_card_artists_website4 {
    width: 100%;
    /* background-color: #fff; */
}

.event_card_artists_website5 {
    width: 80%;
    /* background-color: #fff; */

}



.event_card_artists_website6 {
    width: 100%;
}

.artist_bio_website {
    text-align: center;
}

.artist_bio_website span {
    color: #fff;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    margin-right: 10px;
}

.event_card_artist_box_website {
    display: flex;
    flex-direction: column !important;
    justify-content: center;
}

.event_card_artist_image_website::before {
    content: "";
    position: absolute;
    background-image: url('./images/image_line.svg');
    background-repeat: no-repeat;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
}

.event_card_artist_image_website {
    border-radius: 1000px 1000px 0px 0px;
    position: relative;
}

.event_card_artist_grid {
    /* width: 100%; */
    /* background-color: #fff; */
    /* background-color: red; */
    width: 100% !important;
    margin-left: 11px;
}

.book_now_button {
    border-radius: 4px !important;
}

.book_now_button span {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #FDFCFC;
}

.event_card_box_website_preview{
    width: 100%;
    margin-left: 0px;
    /* min-height: 350px; */
    height: 350px;

    overflow-y: auto;
    padding: 0px !important;
}

.event_card_heading_website_preview{
    font-size: 36px;
    /* margin-top: 155px; */
}

.event_card_info_para_website_preview{
    font-size: 6px !important;
}

.event_card_info_para_website_preview{
    font-size: 6px !important;
}

.book_now_button_preview{
    padding: 1px 5px !important;
}

.book_now_button_preview span{
    font-size:6px !important;
}

.event_card_info_box_website_preview{
    margin-top: 15px !important;
}

.event_card_artist_box_website_preview{
    padding-top: 0px !important;
}

.artist_image_line_new img {
    border-radius: 1000px 1000px 0px 0px;
    /* padding: 16.5px; */
    position: relative;
    width: calc(100% - 10px);
}

@media screen and (max-width: 821px) {
    .event_card_artists_website1 {
        width: 50% !important;
    }

    .event_card_artists_website2 {
        width: 100% !important;
    }

    .event_card_artists_website3 {
        width: 100% !important;
    }

    .event_card_artists_website4 {
        width: 100% !important;
    }

    .event_card_artists_website5 {
        width: 100% !important;
    }

    .event_card_artists_website6 {
        width: 100% !important;
        height: 35%;
    }

    .event_card_info_para_website {
        font-weight: 400 !important;
        font-size: 14px !important;
        text-decoration: none;
    }

    .event_card_buttons_website {
        display: flex;
        flex-direction: column !important;
    }

    .event_card_buttons_website .event_card_link_website {
        margin-top: 20px;
    }
}
@media screen and (max-width: 820px) {
    .event_card_heading_website_preview {
        font-size: 22px;
        line-height: 33px;
        /* margin-top: 674px; */
    }
}
@media screen and (max-width: 600px) {
    .event_card_heading_website_preview {
        font-size: 28px !important;
        line-height: 36px !important;
        /* margin-top: 930px !important; */
    }
}

@media screen and (max-width: 480px) {
    .event_card_heading_website_preview {
        font-size: 28px !important;
        line-height: 36px !important;
        /* margin-top: 673px !important; */
    }
}
@media screen and (max-width: 480px)
{
.event_card_heading_website_preview {
    font-size: 28px !important;
    line-height: 36px !important;
}

}