.gallery_images{
    gap: 20px !important;
}

.gallery_images img{
    border-radius: 16px;
}
.gallery_images_container
{
    /* margin-bottom: 200px; */
    display: flex;
    flex-direction: column;
    gap: 100px;
}
.odd
{
    /* display: flex; */
  flex-direction: row-reverse;
}
.image_container
{
    /* display: flex; */
    /* width: 100%; */
    /* gap: 20px; */
}
.image1
{
    width: 50%;
    height: 100%;
    position: relative;
    object-fit: cover;
    
}
.image1::after{
    content: "";
    position: absolute;
    bottom: 0px;
    left: 0px;
    background: linear-gradient(180deg, rgba(20, 10, 10, 0) 30%, #140a0a 104%);
    height: 100%;
    width: 100%;
}

.image_container2
{
    display: flex;
    width: 50%;
    gap: 20px;
    
}
.div1
{
    width: 50%;
    border-radius: 20px;
}
.image1_img
{
    background-image: cover;
    height: 100%;
    width: 100%;
    border-radius: 20px;
}

.image2
{
    height: 49%;
    margin-bottom: 10px;
    position: relative;
}
.image2::after{
    content: "";
    position: absolute;
    bottom: 0px;
    left: 0px;
    background: linear-gradient(180deg, rgba(20, 10, 10, 0) 30%, #140a0a 104%);
    height: 150%;
    width: 100%;
}
.image2_img
{
    background-image: cover;
    height: 100%;
    width: 100%;
    border-radius: 20px;
}
.image3
{
    height: 49%; 
    position: relative;   
}
.image3::after{
    content: "";
    position: absolute;
    bottom: 0px;
    left: 0px;
    background: linear-gradient(180deg, rgba(20, 10, 10, 0) 30%, #140a0a 104%);
    height: 150%;
    width: 100%;
}

.image3_img
{
    background-image: cover;
    height: 100%;
    width: 100%;
    border-radius: 20px;
}
.div2
{
    width: 50%;
    gap: 10px;
}
.image4
{
    height: 49%;
    margin-bottom: 10px;
    position: relative;
}
.image4::after{
    content: "";
    position: absolute;
    bottom: 0px;
    left: 0px;
    background: linear-gradient(180deg, rgba(20, 10, 10, 0) 30%, #140a0a 104%);
    height: 150%;
    width: 100%;
}
.image4_img
{
    background-image: cover;
    height: 100%;
    width: 100%;
    border-radius: 20px;
}
.image-size-cover img {
    object-fit: cover;
}
.image5
{
    height: 49%;
    position: relative;
    object-fit: cover;
}
.image5::after{
    content: "";
    position: absolute;
    bottom: 0px;
    left: 0px;
    background: linear-gradient(180deg, rgba(20, 10, 10, 0) 30%, #140a0a 104%);
    height: 150%;
    width: 100%;
}
.image5_img
{
    background-image: cover;
    height: 100%;
    width: 100%;
    border-radius: 20px;
}