.hero_section {
  /* min-height: 100vh; */
  /* width: 99vw; */
  width: 100%;
  /* margin-left: -5%; */
  background-image: url("./images/Banner.png");
  background-size: cover;
  background-repeat: no-repeat;
  /* margin-top: 150px; */
  /* margin-top: 50px; */
  overflow: hidden;
}
.banner_container_box {
  padding-top: 50px;
}

.hero_section_web_carousel {
  /* padding-left: 5%; */
  /* padding-right: 5%; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 80vh;
  /* padding-left: 5%; */
  /* padding-right: 5%; */
  height: auto;
  /* width: 100vw; */
  width: 100%;
}

.homeHeading {
  color: white;
  margin-top: 120px;
  margin-bottom: 20px;
  height: 10vh;
  font-weight: normal;
}

/* Select the element you want to style */

.homeWelcom {
  margin: 0px;
  /* Font family */
  /* font-family: 'Open Sans', sans-serif; */
  /* Font weight */
  font-weight: 400;

  /* Font size */
  font-size: 16px;

  /* Line height */
  line-height: 21.79px;
}

.homeTitle {
  margin: 0px;
  /* Font family */
  /* font-family: 'Raleway', sans-serif; */

  /* Font weight */
  font-weight: 500;

  /* Font size */
  font-size: 27px;

  /* Line height */
  line-height: 37.57px;
}

.hero_section .MuiBox-root .css-16pn2q0 {
  margin-bottom: 0px !important;
}

@media screen and (max-width: 600px) {
  .hero_section_web_carousel {
    height: auto;
  }
  .hero_image_add_spacing .space_top_mobile .artist_image_website_box img {
    width: calc(100% - 36px);
  }
  .device_grid_box .artist_image_website_info {
    margin-left: 0 !important;
  }
  .hero_section::after {
    background-image: url("./images/Group.png");
    width: 100%;
    height: 100%;
    background-color: #000;
    top: 0px;
    right: 0;
    position: absolute;
  }
  .hero_section {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  /* .hero_section:after {
        content: "";
        width: 100%;
        height: 100%;
        background: #ff000024 url('./images/Group.png') no-repeat -30px -50px fixed;
        top: 0px;
        right: 0;
        position: absolute;
        display: inline-block;
        } */
}

/* .hero_section{
    min-height: 100vh;
    width: 99vw;
    margin-left: -5%;
    background-image: url('./images/Banner.png');
    background-size: cover;
    background-repeat: no-repeat;
} */
@media screen and (max-width: 1024px) {
  .hero_section {
    min-height: 80vh;
  }
}
@media screen and (min-width: 600px) {
  .hero_section {
    min-height: auto;
  }
}
