.modal_image_popup .MuiDialog-paper{
    width: 32.5vw;
    padding: 22.5px 17.5px;
}

.modal_image_popup .modal_title{
    padding-bottom: 30px;
}

.MuiDialogActions-root{
    justify-content: space-between !important;
    padding-left: 24px !important;
    padding-right: 24px !important;
}

.MuiDialogActions-root button{
    width: 47.5%;
}

.modal_image_popup .MuiDialog-container{
    margin-left: 120px;
}

@media screen and (max-width: 821px) {
    .modal_image_popup .MuiDialog-paper{
        width: 100vw;
        padding: 5px 0px;
        margin: 10px;
    }

    .modal_image_popup .MuiDialog-container{
        margin-left: 0px;
    }
}

@media screen and (max-width: 930px) {
    .modal_image_popup .MuiDialog-paper{
        width: 70vw;
        padding: 5px 0px;
        margin: 10px;
    }
}

@media screen and (max-width: 300px) {
    .modal_image_popup .MuiDialog-paper{
        width: 100vw;
        padding: 5px 0px;
        margin: 10px;
    }
}