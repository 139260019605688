.artist_round_designation{
    font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 14px;
/* identical to box height, or 114% */


color: #D0931B;
margin-top: 0px;
}

.artist_round_image{
    width: 100px;
    height: 100px;
    border-radius: 50%;
}

.artist_round_image_grid{
    padding-right: 10%;
}

.artist_round_name{
    font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 21px;
/* identical to box height, or 114% */


/* Grey/100 */

color: #F8F9FA;
margin-top: 0px;
margin-bottom: 0px;
}

.artist_round_email{
    word-break: break-all;
    font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 16px;

color: #F8F9FA;
}
@media screen and (max-width: 280px) {

    .artist_round_image{
        width: 74px;
        height: 74px;
        border-radius: 50%;
    }
}