.step4_editor_box{
    margin: 20px 0px;
}

.step4_editor_box .rdw-editor-toolbar{
    border: unset !important;
}

.step4_editor_box .rdw-editor-main{
    border: 1px solid #E8EAED !important;
    height: 160px !important;
    border-radius: 8px;
    font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 16px;
padding: 5px 15px;
}

.step4_input_title{
    font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 12px;
line-height: 14px;
color: #221714;
margin-bottom: 10px;
margin-top:0px;
}

.step4_input_title_span{
    color: #7E8085;
    font-weight: 400;
}

.step4_image_upload{
    margin-top: 30px;
}

.step4_image_card_upload{
    height: 83px;
    width: 83px !important;
    width: 100%;
    border-radius: 8px;
    padding-bottom: 5px;
    position: relative;
    padding: 15px;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23903713FF' stroke-width='2' stroke-dasharray='6%2c 6' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
border-radius: 8px;
display: flex;
justify-content: center;
align-items: center;
cursor: pointer;
}

.contact2 {
    padding-top:0px !important;
    margin-top:-10px !important;
}