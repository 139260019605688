.sidebar{
    background: linear-gradient(180deg, #FFFFFF 0%, #FAECD3 141.71%);
    height: 100vh;
    position: fixed;
}

.gradient_bg{
    background: linear-gradient(180deg, #FFFFFF 0%, #FAECD3 141.71%);
}

.logo_container{
    height: 15vh;
}

.menu_container{
    height: 85vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.menu_link{
    text-decoration: unset;
}

.list_item_active:hover{
    background-color: #913713 !important;
}

.content_container{
    width: calc(100vw - 240px);
    margin-left: 240px;
    margin-top: 45px;
}

.content_inner_container{
    padding-left: 24px;
    padding-right: 24px;
}