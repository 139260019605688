.register_dialog_web_padding{
    padding-top: 0px !important;
}

.web_dialog_select .MuiOutlinedInput-root{
    background: #fff;
    height: 44px !important;
}

.web_dialog_select .MuiOutlinedInput-root .MuiSelect-select{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.web_dialog_select .MuiOutlinedInput-root .MuiSelect-select span{
    color:rgba(0,0,0,0.4) !important;
}

.web_dialog_select .MuiFormControl-root{
    width: 100% !important;
    margin: 0px;
}

.web_dialog_grid_container{
    margin-top: 10px !important;
}

.web_dialog_checkbox{
    text-align: center;
}

.web_dialog_checkbox span{
    font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 13px;
text-align: center;

color: #7E8085;
}

.web_dialog_student_upload{
    background: #fff;
    padding:5px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    border-radius: 4px;
    margin-top: 10px;
    cursor: pointer;
}

.web_dialog_student_upload button{
    background: #D0931B;
    color: #fff;
    border: unset;
    padding: 10px 15px;
    font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 14px;
border-radius: 4px;
}

.web_register_error_label{
    font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 14px;
color: #E5767A;
display: flex;
flex-direction: row;
align-items: center;
}

.web_register_error_label img{
    margin-right: 10px;
}

.web_register_select_extra_label{
    font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 14px;
color: #9FA1A6;
}
.divider
{
    color: #E8EAED;
    width: 92%;
}