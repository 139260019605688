.step2_select_dropdown {
}

.step2_heading {
  margin-bottom: 15px;
  color: #221714;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
}

.step2_heading_span {
  color: #7e8085;
  font-weight: 400;
}

.step2_select {
  width: 100%;
}

.accordion_btn {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;

  color: #913713;
}

.collpase_btn {
  transform: rotate(180deg);
}

.artist_heading {
  color: #221714;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 20px;
}

.artist_accordion {
  background: #ffffff;
  /* Shadow/300 */
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 3px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.accordion_box {
  margin-top: 30px;
  margin-bottom: 30px;
}

.accordion_header {
  padding: 0px 30px !important;
 
  
}



.accordion_content {
  margin-top: 0px;
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.accordion_input_grid {
  padding-left: 45px;
}

.editor_box {
  margin: 20px 0px;
}

.editor_box .rdw-editor-toolbar {
  border: unset !important;
}

.editor_box .rdw-editor-main {
  border: 1px solid #e8eaed !important;
  height: 160px !important;
  border-radius: 8px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  padding: 5px 15px;
}

.editor_box_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: #221714;
  margin-bottom: 10px;
  margin-top: 0px;
}

.word_exceed_limit {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #dc4852;
}

.editor_words_limit_error_box {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.accordion_upload_title {
  margin-top: 20px;
  margin-bottom: 15px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: #221714;
}

.step_button_container {
  margin-top: 60px;
  margin-bottom: 45px;
}

.step_confirm_button {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.step2_input {
  min-height: 50vh;
}

.modal_popup_simple .modal_title {
  padding-bottom: 25px;
}

.artistImageBox {
  background-image: url("data:image/svg+xml,%3csvg width=%27100%25%27 height=%27100%25%27 xmlns=%27http://www.w3.org/2000/svg%27%3e%3crect width=%27100%25%27 height=%27100%25%27 fill=%27none%27 rx=%278%27 ry=%278%27 stroke=%27%23903713FF%27 stroke-width=%272%27 stroke-dasharray=%276%2c 6%27 stroke-dashoffset=%270%27 stroke-linecap=%27square%27/%3e%3c/svg%3e");
  border-radius: 8px;
  height: 260px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.menuHorizontal {
  position: absolute;
  right: 10px;
  bottom: 0;
}
.textDotted{
  position: absolute;
  bottom: -9px;
  right: -13px;
  color: #fff;
  padding: 10px;
  cursor: pointer;
}

.delete {
  color: #7e8085 !important;
  padding: 0px 12px 12px 12px !important;
}
