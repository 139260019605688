.buy_cd_step1_membership{
    background: #221714;
    padding: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.buy_cd_step1_box{
    padding-top: 60px;
    padding-bottom: 60px;
}

.buy_cd_step1_membership_label{
    display: flex;
}

.buy_cd_step1_membership_label p{
    font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 28px;
margin-left: 15px;
/* identical to box height, or 117% */


color: #A27152;
}

.buy_cd_step1_membership_link a{
    font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 24px;
letter-spacing: 0.25px;
text-decoration-line: underline;

color: #D5D7DB;
}

.buy_cd_step1_user_details{
    margin-top: 45px;
    background: #221714;
    padding: 30px;
}

.buy_cd_step1_grid{
    padding-top: 0px !important;
}

.buy_cd_step1_user_info{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.buy_cd_step1_user_info h4{
    font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 17px;
color: #D5D7DB;
margin-top: 0px;
margin-bottom: 15px;
}

.buy_cd_step1_user_img{
    width: 64px;
    height: 64px;
    object-fit: cover;
    border-radius: 50%;
}

.buy_cd_step1_user_img_grid{
    text-align: center;
}

.buy_cd_step1_user_email{
    font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 16px;
letter-spacing: 0.1px;
color: #D5D7DB;
margin-bottom: 0px;
margin-top: 0px;
}

.empty_addresses{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #D5D7DB;
   
}


.buy_cd_step1_user_phone{
    font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 16px;
margin-top: 15px;

color: #9FA1A6;
}

.buy_cd_step1_user_grid{
    display: flex;
    justify-content: space-between;
}

.buy_cd_step1_pencil_icon{
    height: 15px;
    cursor: pointer;
}

.user_info_change_password{
    margin-top: 30px;
}

.user_info_change_password p{
    font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 24px;
text-decoration: underline;
color: #D5D7DB;
cursor: pointer;
}

.user_info_edit_grid{
    padding-top: 0px !important;
}

.user_info_edit_grid_container{
    position: relative;
}

.user_info_edit_close{
    position: absolute;
    right: 0px;
    cursor: pointer;
}

.user_info_save_changes{
    margin-top: 30px;
}

.user_info_edit_heading{
    font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 21px;
line-height: 24px;
color: #D5D7DB;
}

.user_info_edit_input_box{
    display: flex;
}

.user_info_edit_image_box{
    padding-top: 20px;
    padding-bottom: 20px;
    position: relative;
}

.user_info_edit_image_box .user_info_edit_img{
    height: 177px;
    width: 177px;
    object-fit: cover;
    border-radius: 20px;
}

.user_info_edit_inputs_box{
    width: 100%;
    padding-left: 60px;
}

.user_info_edit_camera{
    position: absolute;
    right: -14px;
    bottom: 10px;
    border-radius: 50%;
}
@media screen and (max-width:920px) {
.buy_cd_step1_user_info{
    margin-left: 20px;
}
}
@media screen and (max-width:700px) {
    .buy_cd_step1_user_info {
        margin-left: 53px;
        word-break: break-word;
    }
}
@media screen and (max-width:280px){
    .buy_cd_step2_delete_button {
        margin-left: 18px !important;
    }
}
@media screen and (max-width:821px) {

    .buy_cd_step1_membership{
        padding: 10px !important;
        border-radius: 8px !important;
    }
    .user_info_edit_input_box{
        justify-content: space-evenly;
    }
    .buy_cd_step1_membership_label img{
        height: 20px !important;
    }
    .buy_cd_step1_membership_label p{
        font-weight: 700 !important;
font-size: 17px !important;
    }
    .buy_cd_step1_membership_label{
        flex-direction: row !important;
        align-items: center !important;
    }
}

@media screen and (max-width:600px) {
    .user_info_save_changes button{
        width: 100%;
    }
}