.carousel_slide {
  background-color: transparent !important;
}

.carousel_slide_box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* margin-top: 63px; */
  /* margin-top: -30px; */
  padding-left: 3%;
  padding-right: 3%;
  /* margin-top: -75px; */
}

.announcement_card_title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #fff;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  margin-top: 0px;
  margin-bottom: 30px;
}

.announcement_carousel_grid {
  padding: 5px 30px;
}

.announcement_carousel_grid_item {
  border-right: 1px solid #fff;
  padding-left: 30px !important;
  padding-right: 30px;
  padding-top: 0px !important;
}

.announcement_carousel_grid_item:last-child {
  border-right: none !important;
}

.carousel {
  /* overflow: unset !important; */
}

.carousel_hero_banner {
  height: auto !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.carousel_hero_banner > div {
  height: auto !important;
}

.carousel_hero_banner > div > div {
  position: static;
}

@media (max-width: 1024px) {
  .carousel_hero_banner {
    height: 44vh;
  }
}

.announcement_card_subtitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #f8f9fa;
}

.announcement_card_link {
  color: #f8f9fa;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-decoration: none;
}

.announcement_card_link img {
  margin-left: 10px;
}

.gallery_image_box {
  position: relative;
}

.gallery_image_box::after {
  position: absolute;
  content: "";
  background: linear-gradient(
    358.51deg,
    #000000 0.99%,
    rgba(18, 18, 18, 0) 91.66%
  );
  left: 0px;
  top: 0px;
  height: 100%;
  width: 100%;
}

.gallery_image_box img {
  width: 100%;
  height: 400px;
  object-fit: cover;
}

.gallery_image_box_landscape {
  position: relative;
}

.gallery_image_box_landscape::after {
  position: absolute;
  content: "";
  background: linear-gradient(
    358.51deg,
    #000000 0.99%,
    rgba(18, 18, 18, 0) 91.66%
  );
  left: 0px;
  top: 0px;
  height: 100%;
  width: 100%;
}

.gallery_image_box_landscape img {
  width: 100%;
  height: 190px;
  object-fit: cover;
}

.gallery_carousel_grid {
  margin-top: 60px !important;
}

.gallery_image_title {
  margin-top: -30px;
  margin-bottom: 0px;
  color: #babdc2;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  position: absolute;
  padding-left: 30px;
}

.gallery_second_image {
  margin-top: 20px;
}

.gallery_grid_container {
  padding-bottom: 60px;
  padding-left: 120px;
  padding-right: 120px;
}

.next_icon {
  transform: scaleX(-1);
}
.card .card-top img {
  background-image: cover;
  width: 100%;
  height: 100%;
}

.card {
  height: 200px !important;
  border-style: none !important;
}
.card_section {
  /* background-color: #fff; */
  position: relative;
}

.carousel_image_grid {
  /* padding-top: 60px !important; */
  /* display: flex; */
  /* margin: -18px; */
  /* justify-content: center; */
  /* align-items: center; */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px !important;
}
.hero_banner_donate_image {
  max-height: "100%";
  /* width: '100%'; */
  height: "100%";
  /* // object-Fit: 'cover'; */
  cursor: "pointer";
  margin-top: "130px";
  padding: "20px";
}

.main_div {
  width: 97%;
  height: 100%;
  margin-right: 30px;
}
.banner_wrapper {
  display: flex;
  padding: 30px;
}
.left_div {
  width: 35%;
  display: flex;
  /* height: 350px; */
  align-items: center;
  padding: 20px;
  max-width: 300px;
  min-width: 190px;
}

.banner_image {
  border-radius: 20px;
  width: 100%;
  height: fit-content;
}
.right_div {
  max-width: 65%;
  /* padding-left: 30px; */
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding: 20px;
}
.hero_info {
  display: flex;
  /* flex-direction: column; */
}
.heading_desc {
  color: rgba(255, 255, 255, 0.9);
}
.artist_image_website_box {
  position: relative;
  margin-bottom: 28px;
}
.artist_desc {
  color: rgba(255, 255, 255, 0.9);
  text-align: justify;
  font-family: Inter;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  /* overflow: hidden; */
  /* text-overflow: ellipsis; */
  max-width: 100%;
  padding: 5px;
  margin-top: -20px;
  /* width: 75%; */
  max-height: 140px;
  overflow-y: auto;
}
.artist_desc p span {
  color: white !important;
  background-color: transparent !important;
  font-family: Inter;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
}
.artist_desc p strong {
  color: white !important;
  background-color: transparent !important;
  font-family: Inter;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
}
.artist_desc::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.artist_desc::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
.artist_desc::-webkit-scrollbar-thumb {
  background: #eab308;
  border-radius: 10px;
}

::-webkit-scrollbar {
  width: 0px;
  display: none;
}
@media screen and (min-width: 1024px) {
  .arrow {
    margin-top: 0px !important;
  }
}

@media screen and (max-width: 600px) {
  .main_div {
    width: 100%;

    /* padding: 20px; */
  }
  .banner_wrapper {
    display: flex;
    height: auto;
    width: 100%;
    flex-direction: column;
    max-height: 800px;
  }
  .left_div {
    width: 70%;
    height: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 100%;
  }
  .banner_image {
    width: 70%;
    border-radius: 20px;
    background-size: cover;
    max-height: 70%;

    /* position: absolute; */
  }
  .right_div {
    padding-right: 30px;
    max-width: 100%;
    height: 100%;
  }
}

@media screen and (max-width: 821px) {
  .hero_banner_donate_image {
    max-height: "100%";
    width: "100%" !important;
    height: "100%";
    /* // object-Fit: 'cover'; */
    cursor: "pointer";
    margin-top: "130px";
    padding: "20px";
  }
}

@media screen and (max-width: 821px) {
  .carousel_hero_banner {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .carousel_hero_banner > div {
    height: 100% !important;
  }

  .slick-dots > li button::before {
    color: #e97e54 !important;
  }
  .next_icon {
    width: 60px;
  }

  .prev_icon {
    width: 60px;
  }

  .gallery_grid_container {
    padding-left: 80px;
    padding-right: 80px;
  }

  .gallery_image_box_landscape img {
    height: 135px !important;
  }

  .carousel_image_grid {
    /* padding-top: 60px !important; */
    /* background-color: #fff; */
    display: grid;
    place-items: center;
    /* margin-left: 20px; */
    height: auto;
    width: auto;
  }

  .announcement_carousel_grid_item {
    border-right: unset !important;
    border-bottom: 1px solid #f8f9fa !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-bottom: 15px !important;
    padding-top: 15px !important;
  }

  .announcement_carousel_grid_item:last-child {
    border-bottom: unset !important;
  }

  .carousel_hero_banner > div:last-child {
    visibility: hidden !important;
  }
}

.card {
  border: 1px solid #fff;
  border-radius: 8px;
  overflow: hidden;
  height: 400px !important;
  color: #fff;
  position: relative;
}
.center-card {
  background: #000000;
  border-radius: 8px;
  overflow: hidden;
  height: 190px !important;
  color: #fff;
  position: relative;
  /* background-color: #fff; */
}
.slick-prev::before {
  display: none !important;
}
.slick-next::before {
  display: none !important;
}
.slick-dots {
  position: relative !important;
}

.slick-dots > li button::before {
  color: #e97e54 !important;
}
.slick-slide > div {
  margin: 0 10px;
}
.slick-list {
  margin: 0 -10px;
}
.card-top {
  position: absolute;
  top: 0;
  bottom: 0%;
  left: 0;
  right: 0;
}

.card .card-top img {
  background-image: cover;
  width: 100%;
}
.card .card-top h3 {
  color: #fff;
  font-size: 1rem;
}

.card-bottom {
  margin: 10px 20px;
}
.card .card-top > h1 {
  position: absolute;
  bottom: 5%;
  left: 3%;
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
}
.prevArrow {
  margin-left: 10px;
  margin-top: -147% !important;
}

.nextArrow {
  margin-left: -86px;
  margin-top: -147% !important;
  transform: rotate(180deg);
}

/* fake material */

.gallery_container {
  display: flex;
  gap: 20px;
  height: 100%;
  /* background-color: red; */
  border-style: none;
}
.first {
  height: 100%;
  width: 30%;
  position: relative;
}
.first img {
  border-radius: 18px;
}
.shadow {
  position: relative;
}
.shadow::after {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 0px;
  background: linear-gradient(180deg, rgba(20, 10, 10, 0) 50%, #140a0a 104%);
  height: 200%;
  width: 100%;
}
.first img,
.second img,
.second1 img,
.second2 img,
.third img {
  height: 100%;
  width: 100%;
  object-position: top;
  /* display: none; */
  /* object-fit: cover; */
}

.second {
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100%;
  width: 40%;
}
.second1 {
  height: 47%;
  width: 100%;
}
.second1 img {
  border-radius: 18px;
}
.second2 {
  height: 47%;
  width: 100%;
}
.second2 img {
  border-radius: 18px;
}
.third {
  height: 100%;
  width: 30%;
}
.third img {
  background: rgb(146, 144, 144);
  border-radius: 18px;
}
.title {
  font-size: 12px;
  color: #babdc2;
  position: relative;
  left: 16px;
  bottom: 20px;
  font-weight: 400;
  font-family: inter;
  line-height: 14px;
  text-overflow: ellipsis;
}
@media screen and (max-width: 821px) {
  .gallery_container {
    display: flex;
    /* gap: 20px; */
    height: 100%;
    width: 100%;
    /* background-color: red; */
    border-style: none;
  }
  .first {
    display: none;
  }
  .third {
    display: none;
  }
  .second img,
  .second1 img,
  .second2 img {
    height: 100%;
    width: 100%;
    object-position: top;
  }
  .second {
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 100%;
    width: 100%;
  }
  .second1 {
    height: 50%;
    width: 100%;
  }
  .second2 {
    height: 50%;
    width: 100%;
  }
}
