.feedback_top {
  padding-top: 150px;
  padding-bottom: 60px;
}

.feedback_top h4 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 54px;
  text-align: center;
  text-transform: capitalize;
  color: #fdfcfc;
  margin-top: 0px;
  margin-bottom: 30px;
}

.feedback_top p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;
  text-align: center;
  color: #9fa1a6;
  margin-bottom: 0px;
}

.feedback_form {
  width: 50%;
  margin-left: 25%;
  padding-bottom: 60px;
  /* background-color: #fff; */
}
.feedback_form h5 {
  font-size: 12px;
  line-height: 14px;
}

.feedback_buttons_box {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  /* background-color: #fff; */
}

.feedback_buttons_box .Mui-disabled {
  border: 1px solid rgba(255, 255, 255, 0.5) !important;
  color: #fff !important;
}

@media screen and (max-width: 821px) {
  .feedback_form {
    width: 100%;
    margin-left: 0px;
  }
}
