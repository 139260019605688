.hero_heading {
  display: flex;
  font-weight: 400 !important;
  font-size: 32px !important;
  font-family: "Open Sans";
  margin-bottom: 0px;
  margin-top: 20px;
  /* background-color: #fff; */
  line-height: unset;
  text-transform: capitalize;
}

.hero_heading_line {
  line-height: unset !important;
  font-size: 26px !important;
  width: 45% !important;
}

.hero_heading_box {
  margin-top: 60px;
  margin-bottom: 60px;
  margin-bottom: 40px;
}

.hero_booking_line {
  width: 40%;
}

.hero_heading_line {
  margin-top: -10px;
}

.hero_info {
  display: flex;
}
.hero_info_icon_sizes {
  height: 13px;
  width: 12px;
}

.hero_info img {
  margin-right: 15px;
  margin-bottom: 20px;
}

.hero_info_text {
  color: #fff;
  font-family: "Raleway";
  font-weight: 400;
  font-size: 16px;
  margin-top: -5px;
  /* background-color: #fff; */
}

.hero_price_info {
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  margin-bottom: 20px;
}

.hero_price_booking {
  color: #fff;
  font-weight: 400;
  font-size: 30px;
  margin-bottom: 10px;
  font-family: "Open Sans";
  position: relative;
  align-items: center;
  /* padding-left: 28px; */
  margin-bottom: 10px;
}

.hero_price_booking::after {
  content: "";
  /* background: url("./images/line.svg"); */
  background-repeat: no-repeat;
  width: 100%;
  bottom: 0px;
  left: 0px;
  top: 15px;
  position: absolute;
  height: 2px;
  height: 15px;
  /* transform: scaleX(-1); */
}

.hero_price_count {
  display: flex;
}

.hero_price_tag {
  color: #ffffff;
  font-family: "Raleway";
  font-weight: 700;
  font-size: 26px;
  margin-top: 0px;
}

.hero_price_count_para {
  color: #fff;
  font-size: 12px;
  font-family: "Raleway";
  margin-left: 15px;
}

.hero_button_image {
  position: absolute;
  width: 20%;
  margin-left: 20%;
  margin-top: -80px;
}

.hero_button_image img {
  margin-left: 10%;
  cursor: pointer;
}

.hero_heading_box_preview {
  margin-top: 15px;
  margin-bottom: 15px;
}

.hero_info_text_preview {
  font-size: 10px !important;
  margin-top: 0px !important;
}

.hero_price_booking_preview {
  font-size: 20px;
}

.hero_price_tag_preview {
  font-size: 16px;
}

.hero_price_count_para_preview {
  font-size: 14px;
  margin-top: 2px;
  margin-bottom: 0px;
  margin-left: 10px;
}
.hero_heading_span_line {
  /* position: relative; */

  /* max-width: 20ch; */
  /* overflow: hidden;
  text-overflow: ellipsis;
  width: fit-content;
  white-space: nowrap; */
  max-width: unset;
  overflow: unset;
  text-overflow: unset;
  white-space: unset;
}
/* .hero_heading_span_line:hover
{
  max-width: unset;
  overflow: unset;
  text-overflow: unset;
  white-space: unset;
} */

@media screen and (max-width: 821px) {
  .hero_heading {
    font-weight: 400 !important;
    font-size: 36px !important;
    font-weight: 400;
    font-size: 60px;
    font-family: "Open Sans";
    margin-bottom: 0px;
    margin-top: 20px;
    /* background-color: #fff; */
    text-transform: capitalize;
  }

  .hero_heading_line {
    line-height: unset !important;
  }

  .hero_heading_line {
    font-size: 26px !important;
  }

  .hero_heading_span_line {
    position: relative;
    width: auto;
  }

  .hero_heading_span_line::after {
    content: "";
    /* background-image: url("./images/line.svg"); */
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 25px;
    left: 0px;
    bottom: 0px;
    position: absolute;
  }

  .hero_heading_box {
    margin-top: 10px;
    margin-bottom: 60px;
    margin-bottom: 40px;
  }

  .hero_booking_line {
    width: 40%;
  }

  .hero_heading_line {
    margin-top: -10px;
  }

  .hero_info {
    display: flex;
  }

  .hero_info img {
    margin-right: 15px;
    margin-bottom: 20px;
  }

  .hero_info_text {
    color: #fff;
    font-family: "Raleway";
    font-weight: 400;
    font-size: 16px;
    margin-top: -5px;
    /* background-color: #fff; */
  }

  .hero_price_info {
    display: flex;
    flex-direction: column;
    margin-top: 60px;
  }

  .hero_price_booking {
    color: #fff;
    font-weight: 400;
    font-size: 30px;
    margin-bottom: 10px;
    font-family: "Open Sans";
    position: relative;
  }

  .hero_price_booking::after {
    content: "";
    /* background: url("./images/line.svg"); */
    background-repeat: no-repeat;
    width: 100%;
    bottom: 0px;
    left: 0px;
    position: absolute;
    height: 2px;
    height: 15px;
  }

  .hero_price_count {
    display: flex;
  }

  .hero_price_tag {
    color: #ffffff;
    font-family: "Raleway";
    font-weight: 700;
    font-size: 26px;
    margin-top: 0px;
  }

  .hero_price_count_para {
    color: #fff;
    font-size: 12px;
    font-family: "Raleway";
    margin-left: 15px;
  }

  .hero_button_image {
    position: absolute;
    width: 20%;
    margin-left: 25%;
    margin-top: -80px;
  }

  .hero_button_image img {
    margin-left: 25%;
    cursor: pointer;
  }

  .hero_heading_box_preview {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .hero_info_text_preview {
    font-size: 10px !important;
    margin-top: 0px !important;
  }

  .hero_price_booking_preview {
    font-size: 20px;
  }

  .hero_price_tag_preview {
    font-size: 16px;
  }

  .hero_price_count_para_preview {
    font-size: 14px;
    margin-top: 2px;
    margin-bottom: 0px;
    margin-left: 10px;
  }

  @media screen and (max-width: 821px) {
    .hero_heading {
      font-weight: 400 !important;
      font-size: 36px !important;
      font-weight: 400;
      line-height: 48.17px;
    }
    .hero_heading_line {
      width: 45% !important;
    }
    .hero_heading_box {
      margin-bottom: 15px !important;
    }
    .hero_info_text {
      font-weight: 400 !important;
      font-size: 14px !important;
    }
    .hero_price_info {
      margin-top: 15px !important;
    }
    .hero_price_booking {
      font-weight: 400 !important;
      font-size: 24px !important;
    }
    .hero_button_image {
      margin-top: -80px !important;
    }
    .hero_button_image img {
      margin-left: 50%;
      width: 30%;
    }
  }
}

@media screen and (max-width: 1550px) {
  .hero_heading {
    font-weight: 400 !important;
    font-size: 26px !important;
    font-weight: 400;
    line-height: 50px;
    width: 100% !important;
    text-overflow: ellipsis;
    /* height: 50px; */
    /* background-color: #fff; */
  }
}
