.tab_profile_box_top{
    background: #FDFCFC;
/* Shadow/200 */

box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.06), 0px 2px 8px rgba(0, 0, 0, 0.1);
border-radius: 8px;
margin-top:15px;
padding:30px 45px;
}
.btn60percent{
    width: 30%;
}
.tab_profile_image{
    border-radius: 50%;
    height: 64px;
    width: 64px;
    object-fit: cover;
}

.tab_profile_address_box_heading{
    font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 16px;

color: #7E8085;
}

.tab_profile_address_box_line1{
    font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 16px;

color: #221714;
}

.modal_popup_profile .MuiDialog-paper{
    width: 60vw;
    padding: 25px 20px;
    border-radius: 8px;
    height:440px;
    overflow-y: scroll;
    max-width: 60vw !important;
    padding-bottom: 0px;
}

.modal_popup_event_card{
    border: 1px solid #FAE4DB;
    margin-bottom: 15px;
    padding: 15px 30px;
}

.modal_popup_event_card img{
    width: 100%;
    margin-top:5px;
}

.modal_popup_event_ticket{
    display: flex;
    align-items: flex-start;
}

.modal_popup_event_ticket img{
    width: 15px;
}

.modal_popup_event_location_details{
    display: flex;
    margin-top:15px;
}

.modal_popup_event_location_details img{
    width: 15px;
}

.modal_popup_event_location_text{
    margin-left: 15px;
}

.modal_popup_event_details{
    padding-left: 30px;
    padding-right: 30px;
}

.modal_popup_event_ticket_details{
    margin-left: 15px;
}

.modal_profile_popup_order{
    background: #FDFCFC;
    padding:30px 45px;
/* Shadow/200 */

box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.06), 0px 2px 8px rgba(0, 0, 0, 0.1);
border-radius: 8px;
margin-bottom: 30px;
}

.processing_status{
    background: #FFE093;
backdrop-filter: blur(21px);
border-radius: 2px;
padding:7.5px;
font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 18px;
/* identical to box height, or 150% */


/* Dark Grey/200 */

color: #606266;
display: flex;
align-items: center;
justify-content: flex-start;
width: fit-content;
border-radius: 4px;
}

.processing_status img {
    margin-right:5px
}

.modal_popup_order_grid_margin{
    margin-top: 15px !important;
    margin-bottom: 15px;
}

.modal_popup_order_divider{
    height:1px;
    background: #F9DFE0;
    width: 100%;
    margin-top: 30px;
    margin-bottom: 30px;
}

.modal_popup_cd_title{
    font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 12px;
color: #7E8085;
}

.modal_popup_cd_subtitle{
    font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 12px;
color: #7E8085;
}

.share-banner-container {
    padding-top: 16px;
    padding-bottom: 32px;
}


.share-input-container {
    position: relative;
    width: 100%;
    margin-bottom: 32px;
}

.share-input-container input {
    padding-left: 40px;
    width: 100%;
}
.share-svg-container {
    position: absolute;
    left: 0;
    top: 0;
    padding: 10px;
}

.copy-button-container {
    position: absolute;
    right: 0;
    top: 0;
    background-color: white;
    border-radius: 8px;
}
.copy-button-container > button {
    height: 44px;
}
.flex-container {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 40px;
    margin-bottom: 60px;
}
.flex-container svg {
    cursor: pointer;
}
.share-anchor-tag {
    text-decoration: none;
}