.profile_camera_image{
    position: absolute;
    right: -20px;
    bottom: -30px;
    cursor: pointer;
}
.imgProfileRes{
    max-width: 100%;
    width: 150px;
    padding: 10px;
    border-radius: 25px;
    max-height: 160px;
}