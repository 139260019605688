.login-grid-1{
    
}

.login-grid-2{
    background-color:#FAE4DB;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding:0px !important;
    position:relative;
}