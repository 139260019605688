.about_us_image_website {
    padding: 10px;
    width: 93%;
}
.m-mobile{
    display: none;
}
.about_us_heading_img_box {
    margin-top: -23px !important;
    margin-left: 33px !important;
}

.about_us_heading_img {
    width: 131px;
    transform: matrix(-1, 0, 0, 1, 0, 0);
}

.internal_heading_img_box {
    margin-left: 18px !important;
}

.internal_heading_img {
    width: 290px;
}

.about_us_top_website {
    /* padding-top: 150px; */
    padding-bottom: 60px;
}

.about_us_desc {
    text-align: justify;
    padding: 10px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 28px;
    color: rgba(255, 255, 255, 0.9);
    margin-top: 0px;
    margin-bottom: 0px;
}

.about_us_content_website {
    margin-top: 10px;
    margin: 0% 3% 0% 4%;
}

.about_us_bio_website {
    padding: 10px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 26px;
    color: rgba(255, 255, 255, 0.9);
    margin-top: 0px;
    margin-bottom: 0px;
    text-align: justify;
}

.about_us_numbers_website {
    padding-top: 60px;
    padding-bottom: 60px;
}

.about_us_number_box_website h4 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 42px;
    line-height: 48px;
    /* identical to box height, or 114% */

    text-align: center;

    background: linear-gradient(180deg, #FFFFFF 0%, #EF8F42 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    margin-right: 45px;
    margin-top: 0px;
    margin-bottom: 0px;
}

.about_us_number_box_website h6 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 21px;
    line-height: 24px;
    margin-top: 0px;
    margin-bottom: 0px;
    /* or 114% */


    /* Grey/100 */

    color: #F8F9FA;
}

.about_us_number_box_website {
    display: flex;
    flex-direction: row;
}

.about_us_grid {
    border-right: 1px solid #fff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 15px;
    padding-bottom: 15px;
}

.about_us_no_border {
    border-right: none;
}

.artist_performed_website {
    padding-top: 60px;
    padding-bottom: 60px;
    margin-left: 15px;
}

.artist_performed_images_website {
    margin-top: 60px
}


.artist_performed_images_website .artist_image_website_info {
    margin:0px
}

.about_us_artist_view_all_website {
    text-align: center;
}

.about_us_artist_view_all_website a {
    text-decoration: none !important;
}

.about_us_artist_view_all_website p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    /* identical to box height, or 114% */

    letter-spacing: 0.1px;

    /* Grey/100 */

    color: #F8F9FA;
}

.about_us_artist_view_all_website p img {
    margin-left: 15px;
}

.artist_performed_website h2 {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 60px;
    line-height: 80px;
    text-align: center;

    /* Ochre/50 */

    color: #FDFCFC;
}

.about_us_extra_website {
    padding-top: 60px;
    padding-bottom: 10px;
}

.about_us_extra_para_bold_website {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 28px;
    /* or 165% */
    color: rgba(255, 255, 255, 0.9);
    /* padding-right: 35%; */
}

.about_us_extra_para_website {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 26px;
    /* or 153% */


    color: rgba(255, 255, 255, 0.9);
    margin-top: 60px;
}

.about_us_extra_box_website {
    padding: 60px 10%;
}

.about_us_internal_subtitle_website {
    margin-top: 30px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 22px;
    text-align: center;

    /* Ochre/50 */

    color: #FDFCFC;
    padding-left: 25%;
    padding-right: 25%;
}

.about_us_internal_website {
    padding-top: 30px;
    padding-bottom: 60px;
}

.about_us_executive_title_website {
    font-weight: 400;
    font-size: 40px;
    font-family: 'Open Sans';
    color: #F8F9FA;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 0px;
}

.about_us_executive_website {
    margin-top: 60px;
}

.executive_border {
    border-right: 1px solid #fff;
}

.executive_grid {
    margin-bottom: 45px !important;
}

.about_us_executive_images {
    margin-top: 45px;
}

.about_us_extra_expand {
    display: none !important;
}

.about_bio_expand {
    display: none !important;
}

.about_us_top_left
{
    text-decoration: none;
    border: none;
    background-color:  transparent;
    /* outline: none; */
    /* color: white; */
    width: 100%;
    max-width: 100%;
    height: auto;
    transition: all 0.2s ease;
    resize: none;
    /* white-space: wrap; */
    /* text-wrap: wrap; */
    overflow: hidden;
    color: white;
    letter-spacing: 0.1px;
    padding: 10px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 28px;
    margin-top: 0px;
    margin-bottom: 0px;
}
.about_us_top_right
{
     text-decoration: none;
    border: none;
    background-color:  transparent;
    /* outline: none; */
    /* color: white; */
    width: 100%;
    max-width: 100%;
    height: auto;
    transition: all 0.2s ease;
    resize: none;
    /* white-space: wrap; */
    /* text-wrap: wrap; */
    overflow: hidden;
    color: white;
    letter-spacing: 0.1px;
    padding: 10px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 26px;
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 30px;

}
.bottom_para_left
{   
    text-decoration: none;
    border: none;
    background-color:  transparent;
    /* outline: none; */
    /* color: white; */
    width: 100%;
    max-width: 100%;
    height: auto;
    transition: all 0.2s ease;
    resize: none;
    /* white-space: wrap; */
    /* text-wrap: wrap; */
    overflow: hidden;
    color: white;
    letter-spacing: 0.1px;
    padding: 10px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 28px;
    margin-top: 0px;
    margin-bottom: 0px;
    /* padding: 35px; */
    
}
.botttom_para_right
{
    text-decoration: none;
    border: none;
    background-color:  transparent;
    /* outline: none; */
    /* color: white; */
    width: 100%;
    max-width: 100%;
    height: auto;
    transition: all 0.2s ease;
    resize: none;
    /* white-space: wrap; */
    /* text-wrap: wrap; */
    overflow: hidden;
    color: white;
    letter-spacing: 0.1px;
    padding: 10px;
    margin-top: 0px;
    margin-bottom: 0px;
    
    
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 26px;
}

@media screen and (max-width:821px) {
    .section_img_box img
    {
        width: 120px !important;
      
    }
    .about_us_image_website {
        width: 80% !important;
        margin-left: 10% !important;
    }

    .about_us_desc {
        font-size: 14px !important;
    }

    .about_us_bio_website {
        display: none !important;
    }

    .about_bio_expand {
        display: block !important;
        padding: 0px 20px 0 10px;
    }

    .about_bio_expand p {
        font-family: 'Inter';
        font-weight: 400;
        font-size: 14px;
        letter-spacing: 0.1px;
        color: #BABDC2;
    }

    .about_bio_expand span {
        color: #EEA081;
    }

    .about_us_number_box_website {
        flex-direction: column !important;
    }

    .about_us_grid {
        border-right: none !important;
    }

    .about_us_number_box_website h4 {
        font-size: 24px !important;
    }

    .about_us_number_box_website h6 {
        font-size: 14px !important;
    }

    .about_us_extra_para_bold_website {
        font-size: 14px !important;
        padding-right: 0px !important;
    }

    .about_us_extra_para_website {
        display: none !important;
    }

    .about_us_extra_expand {
        display: block !important;
    }

    .about_us_extra_expand p {
        font-family: 'Inter';
        font-weight: 400;
        font-size: 14px;
        letter-spacing: 0.1px;
        color: #BABDC2;
    }

    .about_us_extra_expand span {
        color: #EEA081;
    }

    .about_us_internal_subtitle_website {
        padding-left: 5% !important;
        padding-right: 5% !important;
        font-size: 14px !important;
    }

    .about_us_executive_title_website {
        font-weight: 400 !important;
        font-size: 24px !important;
    }

    .executive_border {
        border-right: none !important;
    }

    .about_us_extra_box_website {
        padding: 60px 10% 90px 10%;
    }
}

@media (max-width:821px) {
    .about_us_heading_img_box {
        margin-top: -12px !important;
        margin-left: 156px !important;
    }

    .about_us_heading_img {
        width: 131px !important;
        transform: matrix(-1, 0, 0, 1, 0, 0);
    }

    .internal_heading_img_box {
        margin-top: -15px !important;
        margin-left: 118px !important;
    }

    .internal_heading_img {
        width: 162px !important;
    }
}

@media (max-width:700px) {
    
    .internal_heading_img_box {
        margin-top: -18px !important;
        margin-left: 93px !important;
    }

    .internal_heading_img {
        width: 162px !important;
    }
    .m-desktop{
        display: none;
    }
    .m-mobile{
        display: block;
    }
}

@media (max-width:600px) {
    .about_us_heading_img_box {
        margin-top: -12px !important;
        margin-left: 115px !important;
    }

    .about_us_heading_img {
        width: 131px !important;
        transform: matrix(-1, 0, 0, 1, 0, 0);
    }

    .internal_heading_img_box {
        margin-top: -18px !important;
        margin-left: 83px !important;
    }

    .internal_heading_img {
        width: 162px !important;
    }
}

@media (max-width:400px) {
    .section_img_box img
    {
        margin-bottom: 10px;
    }
    .about_us_heading_img_box {
        margin-top: -12px !important;
        margin-left: 83px !important;
    }

    .about_us_heading_img {
        width: 131px !important;
        transform: matrix(-1, 0, 0, 1, 0, 0);
    }

    .internal_heading_img_box {
        margin-top: -17px !important;
        margin-left: 93px !important;
    }

    .internal_heading_img {
        width: 162px !important;
    }
}
@media screen and (max-width: 821px)
{
.about_us_image_website {
    width: 94% !important;
 margin-left: 14px !important; 
}
}

