.header_app_bar{
    box-shadow: none !important;
    padding-top: 30px;
    transition: all 0.5s ease-in-out !important;
}

.header_app_bar.header_animation{
    padding-top: 0px;
    box-shadow: 0px 1px 0px #E4E8EE !important;
}

.icon_round_bg{
    background-color: #FEFAF3;
    border-radius: 50%;
    height: 35px;
    width: 35px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.notification_holder{
    position: relative;
    margin-right: 10px;
}

.notification_box{
    position: absolute;
    top: 40px;
    right: 0px;
}

.profile_icon{
    background-color: #FEFAF3;
    display: flex;
    padding: 7.5px;
    align-items: center;
    border-radius: 20px;
}

.profile_detail{
    padding-right: 15px;
}

.profile_image{
    margin-right: 5px;
}

.header_right{
    display: flex;
    justify-content: space-between;
}

.profile_link{
    text-decoration: unset;
}

@media screen and (max-width:767px) {

    .header_right{
        width: 45%;
    }
}

.admin_profile_image {
    height: 22px;
    width: 22px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 4px;
}