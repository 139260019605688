.menu_item{
    display: flex;
}

.menu_img{
    height: auto;
    width: 15px;
    margin-right: 15px;
    object-fit: contain;
}

.menu_item.active{
    background: #913713;
}