.announcement_section 
    {
    padding-top: 0px;
    padding-bottom: 60px;
    height: auto;
    }

.announcement_heading_img_box {
    margin-top: -24px !important;
    margin-left: 14px !important;
}

.announcement_heading_img {
    width: 186px;
}

.announcement_holder {
    padding-top: 30px;
    padding-bottom: 0px;
}

@media screen and (max-width:821px) {
    .announcement_heading_img_box {
        margin-top: -9px !important;
        margin-left: 15.2% !important;
    }

    .announcement_heading_img {
        width: 158px !important;
    }
}

