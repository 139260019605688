.event_details_actions{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.event_details_info{
    background: linear-gradient(360deg, #F9EFEF 0%, rgba(249, 239, 239, 0) 119.65%);
border-radius: 8px;
padding: 15px;
}

.event_back_box{
    cursor: pointer;
    display: flex;
}

.event_back_box span{
    margin-left: 15px;
    font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 17px;
line-height: 22px;
color: #1E100B;
}

.event_details_grid{
    width: 100% !important;
    margin-left: 0px !important;
}

.event_details_preview_image{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
}

.event_details_artist{
    margin-top: 30px;
}

.event_details_artist_img{
    width: 100%;
    border-radius: 8px;
}

.event_details_artist_desc{
    color: #7E8085;
    font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;
}

.event_details_grid_item{
    padding-top: 0px !important;
}

.event_details_accordions{
    margin-top: 15px
}

.event_details_artist_accordion{
    margin-bottom: 15px;
}

.event_details_sponsors{
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 3px 8px rgba(0, 0, 0, 0.1);
border-radius: 8px;
padding: 15px;
margin-top: 45px;
margin-bottom: 60px;
}

.event_details_ack{
    color: #404145;
    font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;
}

.event_details_sponsors_images{
    margin-top: 15px
}

.sponsor_image{
    margin-right: 30px;
    height: 80px;
    width: 80px;
    object-fit: contain;
}