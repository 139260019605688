.artist_image_website_box {
  position: relative;
  margin-bottom: 28px;
  /* width: 100%; */
}

.artist_image_website_box::before {
  content: "";
  position: absolute;
  background: url("./images/image_line.svg");
  background-size: contain;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  /* top: -6px; */
  /* left: -7px; */
}

.artist_image_line_new::before {
  content: "";
  position: absolute;
  background: url("./images/outline.svg");
  background-size: contain;
  background-repeat: no-repeat;
  height: 100%;
  width: 100% !important;
  top:0px !important;
    left:6px !important;
  margin-left: -6px;
  padding: 12px;
}

.artist_image_website_box img {
  border-radius: 1000px 1000px 0px 0px;
  padding: 11.5px;
  position: relative;
  width: 100%;
}

.artist_image_website_box::after {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 0px;
  background: linear-gradient(180deg, rgba(20, 10, 10, 0) 50%, #140a0a 104%);
  height: 100%;
  width: 100%;
}
.artist_image_website_info {
  position: relative;
  /* margin: 3px; */
 
}

.artist_image_line_new .artist_img_tag {
  border-radius: 1000px 1000px 0px 0px;
  max-width: 100%;
  /* padding: 16.5px; */
  position: relative;
  /* width: calc(100% - 25px); */
  width: 100% ;
}

.artist_image_line_new > div {
  border-radius: 1000px 1000px 0px 0px;
  max-width: 100%;
  padding: 16.5px;
  position: relative;
  /* width: calc(100% - 25px); */
  width: calc(100% - -100px) !important;
}

.artist_image_line_new::after {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 0px;
  background: linear-gradient(180deg, rgba(20, 10, 10, 0) 50%, #140a0a 104%);
  height: 100%;
  width: 100%;
}
.all_artist_section_images_website .artist_image_line_new::after {
  width: 100%;
}


.artist_image_website_info h4 {
  color: #fff;
  margin-bottom: 0px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  font-family: "Open Sans";
  text-align: center;
  position: absolute;
  bottom: 20px;
  width: 100%;
}

.artist_image_website_info p {
  color: #fff;
  margin-bottom: 0px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  text-align: center;
  position: absolute;
  bottom: -25px !important;
  width: 100%;
  font-family: "Raleway";
}

.artist_image_website_info_preview h4 {
  font-size: 24px;
  line-height: 27px;
}

.artist_image_website_info_preview p {
  display: flex;
  align-items: center;
  justify-content: end;
}


.artist_image_name_wrapper {
  /* margin-top: -20px; */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: absolute;
  bottom: -5px;
  color: white;
}
.image_artist_six_images
{
  font-size: 13px !important;
  line-height: 13px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.artist_image_name_wrapper > h4{
  max-width: 12ch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: absolute;
 
  /* width: fit-content; */
  /* max-width: unset; */
  /* overflow: unset; */
  /* text-overflow: unset; */
  /* white-space: pre-line; */
}

 .artist_image_website_info .artist_image_name_wrapper > h4:hover{
  position: absolute;
  max-width: 80%;
  overflow: unset;
  text-overflow: unset;
  white-space: unset;
  
}
/* .artist_name_split 
{
  display: flex;
  justify-content: center;
  /* background-color: red; 
  color:#FFFFFF ;
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 30px;
  font-family: "Open Sans";
  
} */

@media screen and (max-width: 2100px) {
  .artist_image_website_info h4 {
    color: #fff;
    margin-bottom: 0px;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    font-family: "Open Sans";
    text-align: center;
    position: absolute;
    bottom:60px;
    /* width: auto; */
    width: 80%;  
    z-index: 1;
  }

  .artist_image_website_info p {
    color: #fff;
    margin-bottom: 0px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;
    text-align: center;
    position: absolute;
    bottom: -25px;
    /* left: 15px; */
    width: 100%;
    font-family: "Raleway";
  }
}

@media screen and (max-width: 900px)
{
  
  .artist_image_website_info
  {
    position: relative;
    /* margin: 3px; */
  }
}





@media screen and (max-width: 600px)
{
  .artist_image_website_info h4{
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    font-weight: 400!important;
    overflow: hidden;
    font-size: 15px !important;
    line-height: 17px !important;
    display: flex;
    align-items: center;
    z-index: 1;
  }
  .event_card_heading_website{
    font-size: 41px !important;
  }
  .artist_image_line_new::before {
    content: "";
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;
    height: 100%;
    width: 100% !important;
    top: 0px !important;
    left: 6px !important;
    width: 100% !important;
}
.artist_image_line_new > div {
  border-radius: 1000px 1000px 0px 0px;
  max-width: 100%;
  padding: 6.5px;
  position: relative;
  /* width: calc(100% - 25px); */
  width: calc(100% - 2px) !important;
}
.MuiGrid-grid-sm-4 .artist_image_line_new > div {
  border-radius: 1000px 1000px 0px 0px;
  max-width: 100%;
  padding: 6.5px;
  position: relative;
  /* width: calc(100% - 25px); */
  width: calc(100% - 2px) !important;
}
.MuiGrid-grid-sm-4 .artist_image_website_info h4 {
  font-size: 12px;
  line-height: 15px;
}
.artist_image_website_box:before {
  left: 2px;
  width: 100%;
}
.artist_image_website_info h4{
  bottom: 32px !important;
}
.artist_image_website_box img {
  width: calc(100% - 25px) !important;
}
.artist_image_website_info p{
  bottom: 11px;
}
}
@media screen and (max-width: 320px)
{
  .artist_image_line_new > div {
    width: calc(100% - 20px) !important;
}
.MuiGrid-grid-sm-4 .artist_image_line_new > div {
  width: calc(100% - 2px) !important;
}
.MuiGrid-grid-sm-4 .artist_image_website_info h4 {
  font-size: 11px;
  line-height: 15px;
}
}
@media screen and (max-width: 280px)
{
  .artist_image_line_new > div {
    width: calc(100% - 2px) !important;
}
.artist_image_website_box img {
  width: calc(100% - 22px) !important;
}
.MuiGrid-grid-sm-4 .artist_image_line_new > div {
  width: calc(100% - 2px) !important;
}
.MuiGrid-grid-sm-4 .artist_image_website_info h4 {
  font-size: 11px;
  line-height: 15px;
}
}


@media screen and (max-width: 280px)
{
.event_card_heading_website {
    font-size: 35px !important;
}
}


@media  screen and (max-width: 1900px) {
  .artist_name_split
  {
  font-size: 25px;
  display: flex;
  justify-content: center;
  /* background-color: red; */
  color:#FFFFFF ;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  font-family: "Open Sans";
  }}

@media  screen and (min-width: 900px) {
  .artist_name_split
  {
  font-size: 13px;
  display: flex;
  justify-content: center;
  /* background-color: red; */
  color:#FFFFFF ;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  font-family: "Open Sans";
  }}

  @media  screen and (max-width: 770px) {
    .artist_name_split
    {
    font-size: 13px;
    display: flex;
    justify-content: center;
    /* background-color: red; */
    color:#FFFFFF ;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    font-family: "Open Sans";
    }}

