.main_box
{
    /* padding: 20px; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-image: url(./images/rangoli.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50% 0%;
  
}
.price_heading
{
font-family: Open Sans;
font-size: 24px;
font-weight: 400;
line-height: 33px;
letter-spacing: 0em;
text-align: center;
color: #FFFFFF;
}
.price_heading_para
{
font-family: Raleway;
font-size: 16px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0.10000000149011612px;
text-align: center;
color: rgba(225,255,255,0.5);
padding-left: 25px;
padding-right: 25px;
}
.price_box
{
    padding: 20px;
    margin: 10px;
    color: aliceblue;
    min-width: 200px;
    max-width: 220px;
}
.price_box:hover
{
    cursor: pointer;
    padding: 20px;
    margin: 10px;
    color: aliceblue;
    background-color:rgba(225,255,255,0.05);
    border-radius: 20px;
}
.priceDetails_heading
{
    font-family: Inter;
font-size: 18px;
font-weight: 700;
line-height: 22px;
letter-spacing: 0px;
text-align: center;
min-height: 63px;

}
.priceDetails_price
{
    /* //styleName: H900/Medium; */
font-family: Inter;
font-size: 42px;
font-weight: 500;
line-height: 48px;
letter-spacing: 0px;
display: flex;
justify-content: center;
padding-top: 10px;
padding-bottom: 10px;
text-align: justified;
align-items: center;

}
.priceDetails_static
{
    font-family: Inter;
font-size: 14px;
font-weight: 400;
line-height: 16px;
letter-spacing: 0.10000000149011612px;
text-align: left;
color: #7E8085;


}
.priceDetails_desc
{
font-family: Inter;
font-size: 14px;
font-weight: 400;
line-height: 16px;
letter-spacing: 0.10000000149011612px;
text-align: center;
max-width: 212px;
min-height: 35px;
color: #BABDC2;
display: flex;
justify-content: center;
align-items: center;
}
.priceDetails_priceBox
{
    display: flex;
    color: aliceblue;
    flex-wrap: wrap;
    justify-content: center;
}
.priceDetails_bottom_decs
{
    display: flex;
    justify-content: center;
    /* align-items: center; */
    flex-direction: column;
    width: 50%;
    color: aliceblue;
    /* background-color: red; */
}
.priceDetails_bottom_decs_para
{
    font-family: Inter;
font-size: 16px;
font-weight: 400;
line-height: 16px;
letter-spacing: 0.10000000149011612px;
text-align: left;
color: #BABDC2;
margin-left: 5px;
}
.copyright_content1
{
    font-family: Raleway;
font-size: 12px;
font-style: italic;
font-weight: 400;
line-height: 14px;
letter-spacing: 0.10000000149011612px;
text-align: center;
color: rgba(225,255,255,0.5);
margin-top: 40px;
padding-left: 15px;
padding-right: 15px;
}
.copyright_content2
{
    font-family: Raleway;
font-size: 12px;
font-style: italic;
font-weight: 400;
line-height: 14px;
letter-spacing: 0.10000000149011612px;
text-align: center;
color: rgba(225,255,255,0.5);
padding-left: 25px;
padding-right: 25px;
padding-bottom: 30px;
}
hr
{
    border: 0.7px solid #7E8085
}
.priceDetails_description
{
    display: flex;
    align-items: start;
}
.priceDetails_description image
{
    padding-top: 10px;
}

.btn_parent
{
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 100%; */
    /* width: 100%; */
    margin-top: 20px;
}

.btn_parent button
{
    padding: 10px;
    width: 80%;
    border-radius: 5px;
    background-color: transparent;
    color: white;
    border: 1px solid #BABDC2;
}
.btn_parent button:hover {
    background-color: white;
    color: black;
    cursor: pointer;
}
.btn_wrapper
{
    display: flex;
    justify-content: center;
    align-items: center;
    width:100%;
    gap: 20px;
    margin-top: 10px;
    
}
.increment
{
    background-color: transparent;
    width: 20%;
    padding: 6px ;
    background-color: transparent;
    color:#BABDC2 ;
    border: 1px solid #BABDC2 ;
    font-size: 25px;
    border-radius: 5px;
}
.decrement
{
    background-color: transparent;
    width: 20%;
    padding: 6px ;
    background-color: transparent;
    color:#BABDC2 ;
    border: 1px solid #BABDC2 ;
    font-size: 25px;
    border-radius: 5px;



}

.ticket_input
{
    width: 20%;
    padding: 6px;
    background-color: transparent;
    color: #BABDC2;
    border: 1px solid #BABDC2 ;
    font-size: 25px;
 border-radius: 5px;
    text-align: center;





}




@media (max-width: 920px) {
    .priceDetails_bottom_decs
    {
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 95%;
        color: #BABDC2;
        padding: 20px;
    }
}
