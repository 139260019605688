.participant_list_back_btn{
    font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 17px;
line-height: 22px;
color: #1E100B;
display: flex;
align-items: center;
}

.participant_list_back_btn img{
    margin-right: 15px;
}