.event_date_day_grid{
    padding-top: 15px;
    padding-bottom: 15px;
}

.react-datepicker__day--disabled{
    color: #ccc !important;
}
.close_image
{
    position: relative;
    right: -170px;
    top: -15px;
}
.am-pm-btn.active-btn-true{
    background-color: #FAE4DB !important 
  }

.Dropdown_conatiner{
    border: 1px dashed #913713;
    border-radius: 8px;
    margin-bottom: 20px;
    
    h3{
        font-weight: normal;
        margin: 10px;
        font-size: 14px;
    }

    button{
        padding: 14px 40px;
        border: 1px solid #913713;
        border-radius: 8px;
        color: #913713;
        background-color: transparent;
    }
}

.Dropdown_innerDiv{
    width: 40%;
    margin: auto;
    text-align: center;

    p{
        font-size: 12px;
        color: #7E8085;

        span{
            color: black;
        }
    }

}