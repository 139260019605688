.event_status {
  text-transform: capitalize;
}

.event_management_artist_image3 {
  width: 100%;
  justify-content: space-between !important;
}

.event_management_artist_image4 {
  width: 100%;
  justify-content: space-between !important;
}

.event_management_detail_box_mid {
  justify-content: center !important;
}

/* .event_management_detail_box_mid
  .event_management_top_title
  .event_management_menu_dots {
  display: none;
} */

.event_management_detail_box_mid .event_management_top_title {
  justify-content: center !important;
}

.event_management_detail_box_mid .event_management_top_title_content {
  text-align: center;
}
.spanOverLapcss{
  overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.event_management_detail_box_mid .event_management_menu_dots {
  position: absolute;
  top: 0px;
  right: 0px;
}

.participant_link {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;

  letter-spacing: 0.1px;
  text-decoration-line: underline;
  color: #221714;
}

.participant_link img {
  margin-left: 5px;
}

.event_management_draft_status {
  text-align: center;
}

.event_management_draft_status span {
  background: #d9d9d9;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  padding: 5px 10px;
  color: #031f07;
  border-radius: 4px;
}

.event_management_draft_button {
  text-align: center;
  margin-top: 30px;
}

.event_management_details_draft {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.toggle_menu_events{
    height: 110px !important; 
}

.toggle_menu_events_past {
  height: 45px !important;
}
