.music_masala_top {
  padding-top: 150px;
}

.music_masala_subtitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 24px;
  text-align: center;
  color: #fdfcfc;
}

.music_masala_artist_images {
  width: 40%;
  display: flex;
  margin-left: 30%;
  padding-top: 60px;
  padding-bottom: 60px;
}

.music_masala_artist_image {
  display: flex;
  flex-direction: column !important;
  align-items: center;
}

.music_masala_artist_image p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;
  text-align: center;
  color: #fdfcfc;
}
.know_more_button
{
  height: 36px;
  width: 132px;
  border-radius: 6px;
  padding: 10px;
  gap: 10px;
}


.know_more_button span {
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #fdfcfc;
}

.music_masala_article_section {
  padding-top: 60px;
  padding-bottom: 60px;
}

.music_masala_article_section_content {
  /* width: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column !important;
}

.heading {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 24px;
  margin-top: 60px;
  margin-bottom: 45px;
  color: #f8f9fa;
  
}

.music_masala_article_section_content h6 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 24px;
  /* identical to box height, or 114% */

  /* Grey/100 */

  color: #f8f9fa;
  margin-top: 0px;
  margin-bottom: 30px;
}

.music_masala_article_section_content p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.1px;
  color: #f8f9fa;
  margin-top: 0px;
}

.music_masala_article_link {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.1px;
  color: #f8f9fa;
  text-decoration: none;
  margin-top: 30px;
  margin-bottom: 60px;
}

.music_masala_article_link img {
  margin-left: 10px;
}

.music_masala_web_section p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;
  text-align: center;

  color: #fdfcfc;
}

.music_masala_web_section {
  padding-top: 60px;
  padding-bottom: 60px;
}

.music_masala_indian_art {
  padding-top: 60px;
  padding-bottom: 60px;
}

.heading {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 32px;
  width: 90%;
  text-align: center;
  color: #f8f9fa;
}

.music_masala_buy_cd h4 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  color: #f8f9fa;
  text-align: center;
}

.music_masala_buy_cd_grid {
  width: 80% !important;
  margin-left: 10%;
}

.music_masala_buy_cd {
  padding-top: 40px;
  padding-bottom: 40px;
}

.music_masala_buy_cd_box {
  display: flex;
  flex-direction: column !important;
  justify-content: center;
  align-items: center;
  /* background-color: #fff; */
}

.music_masala_buy_cd_box p {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;

  color: #fdfcfc;
  margin-top: 30px;
}

.music_masala_cd_link {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.1px;
  color: #f8f9fa;
  text-decoration: none;
  padding: 3px;
  margin-bottom: 10px;
}

.music_masala_cd_link img {
  margin-left: 10px;
}

.music_masala_other_sites {
  padding-top: 60px;
  padding-bottom: 60px;
}

.music_masala_other_sites h4 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  color: #f8f9fa;
  text-align: center;
  margin-left: -15px;
}

.music_masala_other_site_grid {
    width: auto;
    align-items: center;
    justify-content: center;
}

.music_masala_other_sites_box {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  padding-left: 30px;
  padding-right: 30px;
  margin-left: -15px !important;
}

.music_masala_flex_end {
  align-items: flex-end;
}

/* .music_masala_flex_start {
  align-items: flex-start;
} */

.music_masala_box_two {
  padding-left: 0px !important;
  }

.music_masala_other_sites_box h6 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 21px;
    line-height: 24px;
    margin: 0%;
    padding: 0%;
    /* text-align: center; */
    width: 201px;
    color: #FDFCFC;
    margin-bottom: 0px;
    margin-top: 0px;
}

.music_masala_other_sites_box p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #BABDC2;
}

.music_masala_other_site_link {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.1px;
    color: #F8F9FA;
    text-decoration: none;
    width: 75px;
    border-radius: 3px;
    padding: 3px;
  
}

.music_masala_other_site_link img {
  margin-left: 10px;
}

.music_masala_border_right {
  border-right: 1px solid #fff;
}
.music_masala_box {
  font-weight: 400;
  line-height: 22px;
}

.music_masala_artist_image button {
  margin: 0%;
  padding: 10px 5px !important;
  max-width: 300px;
  width: 100px;
}

.heading
{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}


/* media query */
@media (max-width: 480px) {
    .heading {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 30px;
        line-height: 24px;
        margin-top: 60px;
        margin-bottom: 45px;
        color: #F8F9FA;
        width: 90%;

    }
    .music_masala_other_site_grid {
        width: auto;
        align-items: center;
    }
    .music_masala_other_sites_box h6 {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 17px;
        width: 164px;
        line-height: 24px;
        text-align: center;    
        color: #FDFCFC;
        margin-bottom: 0px;
        margin-top: 0px;
        margin-left: -30px;
    }
    .music_masala_other_sites_box p {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        width: 170px;
        line-height: 24px;
        text-align: center;
        margin-left: -20px;
    
        color: #BABDC2;
    }
    .music_masala_other_site_link    {
        align-items: center;
    }
    

}


@media (max-width: 400px) {


    .music_masala_other_sites_box h6 {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 17px;
        line-height: 24px;
        margin: 0%;
        padding: 0%;
        color: #FDFCFC;
        margin-bottom: 0px;
        margin-top: 0px;
        margin-left: -25px;
    }
    .music_masala_other_sites_box p {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        margin-left: -20px;    
        color: #BABDC2;
    }
    


}

@media (max-width: 280px)
{
  .event_image img
  {
    width: 100%;
  }
}

