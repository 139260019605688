.gototop_mainDiv{
  height: 40px;
  width: 40px;

  z-index: 1000;
  position: fixed;
  bottom: 40px;
  right: 40px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #2C2323;
  border-radius: 50px;

  cursor: pointer;
  opacity: 1;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 0.7;
  }

  img{
    height: 12px;
    width: 12px;
  } 
}
