.options_grid {
  display: flex;
  align-items: center;
  flex-direction: row !important;
  justify-content: space-between;
}
html body {
  overflow-x: hidden !important;
}
.home_container {
  overflow-x: hidden;
  background: #140a0a;
  padding-left: 5%;
  padding-right: 5%;
}

.logo_grid {
  display: flex;
  align-items: center;
}

.web_grid_prop {
  padding-top: 0px !important;
  padding-bottom: 0px;
}

.web_header_grid {
  z-index: 1000;
  padding-top: 15px;
  padding-bottom: 15px;
  /* position: fixed; */
  width: 100%;
  left: 0px;
  background: #140a0a;
}

.web_header_grid_no_bg {
  background: transparent;
}

.web_layout_grid {
  width: calc(100vw - 10%);
  min-height: 100vh;
}

.web_header_mobile_option {
  display: none !important;
}

@media screen and (max-width: 821px) {

  .main_logo_link{
    height: 50px;
    position: relative;
    left: 0;
  }
  .main_logo_link img{
    height: 100%;
    position: absolute;
    top: 0;
    left: -180px;
  }

  .logo_grid {
    justify-content: center !important;
  }
  .web_header_grid {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
    height: 50px;
  }
  .web_header_mobile_option {
    display: block !important;
    position: absolute !important;
    top: 32.5px;
    right: 5%;
  }
  .web_header_mobile_menu {
    background: #140a0a;
    height: 80vh !important;
    width: 100vw !important;
    position: fixed;
    z-index: 9999999999999;
    top: 0px !important;
    left: 0px !important;
    overflow: unset !important;
    padding-top: 10vh;
    padding-bottom: 10vh;
    display: flex;
    flex-direction: column !important;
    justify-content: space-between;
    align-items: center !important;
  }
  .web_header_mobile_option_titles {
    text-decoration: none !important;
  }
  .web_header_mobile_option_titles h6 {
    font-family: "Raleway";
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 38px;
    text-align: center;
    color: #fdfcfc;
    margin-top: 15px;
    margin-bottom: 15px;
    border-radius: 50%;
    /* background-image: url(images/User.svg); */
  }
  .web_header_mobile_close {
    position: absolute;
    right: 5%;
    top: 32.5px;
  }
}

@media screen and (max-width: 920px) {
  .web_header_grid {
    z-index: 1000;
    padding-top: 15px;
    padding-bottom: 15px;
    /* position: fixed; */
    /* width: 59% !important; */
    left: 0px;
    background: #140a0a;
  }
}
@media screen and (max-width: 1024px) {
  .web_header_grid {
    z-index: 1000;
    padding-top: 15px;
    padding-bottom: 15px;
    /* position: fixed; */
    width: 80%;
    left: 0px;
    background: #140a0a;
  }
}
@media screen and (max-width: 900px) {
  .web_header_grid {
    z-index: 1000;
    padding-top: 15px;
    padding-bottom: 15px;
    /* position: fixed; */
    width: 100% !important;
    left: 0px;
    background: #140a0a;
  }
}
