.section_heading {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  color: #fff;
  text-align: center;
  margin-top: 10px !important;
  /* margin-bottom: 0px !important; */
}


.section_subtitle {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
  line-height: 33px;
  text-align: center;
  color: #fdfcfc;
}
.parent_section_heading
{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.section_img_box img
{
  margin-right: 38px !important;
  display: none;
}


@media screen and (min-width: 421px) and (max-width: 821px) {
  .section_heading {
    font-weight: 400 !important;
    font-size: 36px !important;
    margin-bottom: 0px !important;
    /* background-color:grey; */
  }

  .section_subtitle {
    font-weight: 400 !important;
    font-size: 16px !important;
  }
}

@media screen and (max-width: 280px) {
  /* .parent_section_heading {
    position: absolute;
    top: 15px;
    right: 20px;
  } */

  .section_heading {
    font-weight: 400 !important;
    font-size: 30px !important;
    line-height: 42.82px !important;
    margin-bottom: 0px !important;
    /* margin-left: -43px; */
  }

  .parent_section_heading img {
    width: 94px;
    margin-right: 67px !important;
  }
}

@media screen and (min-width: 310px) and (max-width: 420px) {
  .section_heading {
    font-weight: 400 !important;
    font-size: 32px !important;
    line-height: 42.82px !important;
    margin-bottom: 0px !important;
  }

  .section_subtitle {
    font-weight: 400 !important;
    font-size: 16px !important;
  }
}

