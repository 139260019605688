.web_otp_dialog_grid_padding{
    padding-top: 0px !important;
}

.web_otp_dialog_resend_box{
    margin-top: 15px;
    margin-bottom: 30px;
}

.web_otp_dialog_resend_box p{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    color: #F4C2AE;
    margin-top: 0px;
    margin-bottom: 0px;
}

.web_register_error_label{
    font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 14px;
color: #E5767A;
display: flex;
flex-direction: row;
align-items: center;
}

.web_register_error_label img{
    margin-right: 10px;
}
.section_img_box {
    width: 300%;
    text-align: center;
    margin-top: -31px;
    margin-left: 42px;
}