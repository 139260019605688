.ack_section h4{
    color: #fff;
    font-style: normal;
font-weight: 400;
font-size: 60px;
line-height: 80px;
font-family: 'Open Sans';
margin-top:0px;
margin-bottom: 0px;
}

.ack_section{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 60px;
    padding-bottom: 60px;
}

.ack_section p{
    color: #D5D7DB;
    font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 17px;
line-height: 26px;
}

.ack_section_brands{
    width: 100%;
}

.ack_section_brands img{
    margin-right: 30px;
    height: 80px;
    width: 80px;
    object-fit: contain;
}

@media screen and (max-width:821px) {
    .ack_section h4{
font-weight: 400 !important;
font-size: 32px !important;
    }
    .ack_section p{
font-weight: 400 !important;
font-size: 14px !important;
    }
}