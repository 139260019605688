.membership_section{
    padding-top: 0px;
    padding-bottom: 60px;
}

.membership_heading_img_box {
    margin-top: -32px !important;
    margin-left: 8px !important;
}
.widthclass100{
    width: 62%;
}
.membership_section_heading_img {
    width: 185px;
}

.membership_content{
    background-image: url('./images/rangoli.svg');
    background-repeat: no-repeat;
    background-size: contain;
    display: flex;
    flex-direction: column !important;
    align-items: center;
}

.rotate_diya{
    transform: scaleX(-1);
}

.membership_timeline{
    font-family: 'Raleway';
font-style: normal;
font-weight: 400;
font-size: 16px;
color: #fff;
text-align: center;
}

.membership_details{
    margin-top: 90px
}

.membership_price{
    font-family: 'Open Sans';
font-style: normal;
font-weight: 400;
font-size: 40px;
color: #fff;
text-align: center;
margin-top: 15px;
margin-bottom: 15px;
}

.membership_price span{
    font-family: 'Raleway';
font-style: normal;
font-weight: 700;
font-size: 40px;
}

.membership_passes{
    font-family: 'Raleway';
font-style: normal;
font-weight: 400;
font-size: 14px;
text-align: center;
color: rgba(255, 255, 255, 0.7);
}

.membership_button{
    width: 100%;
    text-align: center;
    margin-top: 90px;
}
@media screen and (max-width:600px)  {
    .widthclass100{
        width: 100%;
    }
}
@media screen and (max-width:821px)  {

    .membership_details{
        margin-top: 45px !important;
    }
    .membership_timeline{
        font-size: 14px !important;
        font-weight: 400 !important;
    }
    .membership_price{
        font-size: 24px !important;
    }
    .membership_price span{
        font-size: 24px !important;
    }
    .membership_passes{
        font-size: 12px !important;
    }
    .membership_button{
        margin-top:45px !important
    }
    .membership_heading_img_box {
        margin-top: -10px !important;
        margin-left: 2.2% !important;
    }
    .membership_heading_img {
        width: 118px !important;
    }
  
}


