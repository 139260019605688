.block_span {
  color: #dc4852;
  text-decoration: underline;
  text-decoration-color: #dc4852;
  margin-right: 30px;
  cursor: pointer;
}

.delete_img {
  height: 12.5px;
  width: 12.5px;
  cursor: pointer;
  margin-right: 30px;
}

.edit_img {
  height: 12.5px;
  width: 12.5px;
  cursor: pointer;
  margin-right: 30px;
}

.dots_img {
  height: 12.5px;
  width: 12.5px;
  cursor: pointer;
  margin-right: 30px;
}

.MuiTableCell-root {
  border-bottom: none !important;
}

.Mui-checked {
  color: #913713 !important;
}

.dots_dropdown_box {
  position: relative;
}

.dots_dropdown_holder_box {
  position: absolute;
  min-width: 200px;
  background: #fdfcfc;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 3px 8px rgba(0, 0, 0, 0.1);
  z-index: 999;
  right: 44px;
  top: -58px;
}

.dots_dropdown_holder_box ul {
  list-style: none;
  padding-left: 10px;
  padding-right: 10px;
}

.dots_option_li {
  padding: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
}

.dots_option_li img {
  margin-right: 15px;
  height: 14px;
}

.dots_option_li span {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;

  color: #7e8085;
}

.dots_option_li_primary span {
  color: #913713 !important;
}
