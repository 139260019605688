
.web_dialog_checkbox{
    text-align: center;
}

.web_dialog_checkbox span{
    font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 13px;
text-align: center;

color: #7E8085;
}

.web_login_forgot_password_box{
    position: relative;
}

.web_login_forgot_password{
    position: absolute;
    font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 12px;
line-height: 14px;
color: #FFC3AB;
top: -11px;
right: 0px;
cursor: pointer;
}
.dialogue_Content
{
    overflow-x: hidden;
}