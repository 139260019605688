.buy_cd_step3_box{
    padding-top: 60px;
}

.buy_cd_step3_ticket{
    background: #221714;
border-radius: 8px;   
padding: 30px;
}

.buy_cd_step3_ticket_title{
font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 24px;

color: #D5D7DB;
margin-top:0px;
margin-bottom: 30px;
}

.buy_cd_step3_event_banner{
    width: 100%;
}

.buy_cd_step3_attendee_details{
    display: flex;
    align-items: flex-start;
}

.buy_cd_step3_attendee_details p{
    font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 17px;
line-height: 22px;
color: #D5D7DB;
margin-left: 15px;
margin-top: 0px;
}

.buy_cd_step3_attendee_details p span{
    font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 14px;
color: #D5D7DB;
}

.buy_cd_step3_ticket_details{
    margin-top:30px
}

.buy_cd_step3_attendee_names{
    margin-left: 15px;
}

.buy_cd_step3_attendee_names span{
    font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 16px;
/* identical to box height, or 114% */

letter-spacing: 0.1px;

/* Grey/500 */

color: #9FA1A6;
}

.buy_cd_step3_ticket_location{
    display: flex;
    margin-top: 30px;
}

.buy_cd_step3_ticket_location p{
    font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 16px;
letter-spacing: 0.1px;
color: rgba(255, 255, 255, 0.8);
margin-left: 15px;
margin-top: 0px;
margin-bottom: 0px;
}

.buy_cd_step3_ticket_time{
    display: flex;
    margin-top: 30px;
}

.buy_cd_step3_ticket_time p{
    font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 16px;
letter-spacing: 0.1px;
color: rgba(255, 255, 255, 0.8);
margin-left: 15px;
margin-top: 0px;
margin-bottom: 0px;
}

.user_profile_order_box{
    background: #221714 !important;
    border-radius: 0px !important;
    margin-top: 30px;
}
.user_profile_order_title{
    color: #9FA1A6 !important;
}

.user_profile_order_subtitle{
    color: #9FA1A6 !important;
}

.user_profile_cd_grid{
    display: flex;
    flex-direction: column !important;
    justify-content: center;
}

.user_processing_status{
    background: linear-gradient(0deg, rgba(248, 223, 134, 0.05), rgba(248, 223, 134, 0.05)), radial-gradient(97.57% 210.75% at 0.9% 2.98%, rgba(16, 0, 0, 0.2) 0%, rgba(255, 254, 250, 0) 100%) !important;
backdrop-filter: blur(21px) !important;
color: #BABDC2 !important;
}

@media screen and (max-width:821px) {
    .buy_cd_step3_ticket_title{
        font-size: 17px !important;
    }
}